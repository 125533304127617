.prsazdczscfogresssss {
  display: flex;
  justify-content: center;
  font-size: 15px;


}

.ant-modal .css-dev-only-do-not-override-12upa3x {
  width: "816"
}


@media screen and (max-width: 520px) {
  .chapter_dash {
    flex-direction: column !important;
  }

  .ant-modal-content {
    width: 368px !important;
  }

  .nav-pills .nav-link {
    margin-left: 2rem !important;
    font-size: 12px !important;
  }
}




/* @media (min-width: 563px) and (max-width: 768px) {}



@media (min-width: 769px) and (max-width: 820px) {}


@media (min-width: 821px) and (max-width: 1024px) {} */