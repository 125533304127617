.instructordashboard_main {
  margin-top: 64px;
}

.create_img img {
  width: 100%;
  height: 300px;
}

.create_img {
  /* background-image: url("../../../images/COURSES.jpeg"); */
  width: 100%;
  background-size: cover;
  height: 300px;
  background-position: bottom;
}

.instructordashboard_main_heading {
  margin-top: 1rem;
}

.instructordashboard_main_heading h1 {
  font-family: poppins;
  font-size: 1.3rem;
  color: #363945;

  font-weight: bolder;
}

.course_card {
  padding: 10px;
}

.card-title {
  display: flex;
  gap: 10px;
  margin: 0;
}

.card-title a {
  text-decoration: none;
  font-size: 0.9rem;
}

.card-title h4 {
  text-decoration: none;
  font-size: 0.8rem;
  margin: 0;

  font-weight: 600;
}

.card-text {
  font-size: 16px;
  color: #363945;
  text-align: justify;
  display: flex;
  gap: 10px;
}

.card-text h6 {
  font-size: 2rem;
  color: #363945;
  text-align: justify;
  display: flex;
  gap: 10px;
}

.card-text p {
  font-size: 0.8rem;
  font-weight: 400;
  color: #363945;
}

.image img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.course_category {
  padding-right: 18px !important;
}


@media (min-width: 521px) and (max-width: 768px) {

  /* .app-default {
        width: auto !important;
      } */
  .course_category {
    width: 100% !important;

  }

  .add_course_section_snd {
    width: 100% !important;

  }



}

@media (min-width: 521px) and (max-width: 820px) {
  .add_course_section_snd {
    width: 100% !important;

  }
}

