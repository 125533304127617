.commingsoon {
  /* background-image: url("..assets/images/cyberheadlogo.png"); */
  /* backdrop-filter: blur(10px); */

  /* height: 100vh; */

}





.commimgsoondfdgdg {
  width: 550px;
  height: 280px;
  background: rgba(225, 255, 255, 0.1);
  backdrop-filter: blur(10px);

  color: white;
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);
  position: relative;
  left: 37%;

  margin-bottom: 6rem;
  z-index: -1;



}







@keyframes fadeIn {
  from {
    top: 20%;
    opacity: 0;
  }

  to {
    top: 100;
    opacity: 1;
  }

}

@-webkit-keyframes fadeIn {
  from {
    top: 20%;
    opacity: 0;
  }

  to {
    top: 100;
    opacity: 1;
  }

}

.commimg {
  position: absolute;
  left: 50%;
  /* top: 50%; */
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  animation: fadeIn 1000ms ease;
  -webkit-animation: fadeIn 1000ms ease;

}

h1 {
  font-size: 4.0vw;
  line-height: 1;
  color: black;
  font-weight: 500;
}