.emailverfication_box{
    border-radius: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    bottom: 0;
    border: none;
    width: 50%;
    padding: 30px;
    background: #ddddddb5;
    box-shadow: 0 0 2px 2px #ddd;
}

.img_section{
    
}
.emailverfiy_btn button{
    line-height: 22px;
    font-size: 17px;
    color: white;
   
    /* display: inline-block; */
   
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    /* text-transform: uppercase; */
    transition: all ease-in-out 0.3s;
    border: 1px solid #4aa5f0;
    /* border-radius: 47px; */
    text-transform: capitalize;
   
   
}
.emailstyle{
    display: flex;
    justify-content: center;
}   




.alert_green {
    margin-top: 2rem;
    color: black;
    background-color: #7eed7e8f;
    border-color: #0080008f;
    margin: 0;
    border: 2px solid transparent;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 2px solid transparent;
    border-radius: 0.25rem;
    font-size: 0.8rem;
    width: 59%;
  }
