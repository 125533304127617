
  

  
  .student-course {
    width: 500px;
    padding: 2px;
  }
  
  .detail-page {
    margin-top: 23px;
  }
  
  .list-list {
    font-size: initial;
  }
/*   
  .main {
   
    margin-top: 98px;
  } */
  
  /* .course-titleee {
    margin: 13px;
  } */
  
  .card-cardrgdr {
    width: 286px;
  }
  
  /* .text-dark.fw-bold.text-hover-primary.mb-1.fs-6 {
    margin-top: 16px;
  } */
  
  .course-img {
    display: flex;
  
    column-gap: 10px;
    text-align: center;
  }
  
  .voucher-code {
    background-color: white;
    width: 28%;
    align-items: center;
    margin-top: 41px;
    padding: 38px;
    border-radius: 13px;
    margin-left: 14rem;
    margin-left: auto;
    margin-right: auto;
  }
  .require1 {
    margin-top: 10px;
  }
  
  .offer {
    font-size: 26px;
  }
  
  .card-cardrgdr {
    width: 194px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .main-page {
    padding: 14px;
  }
  
  
  .reveal-code {
    font-size: x-large;
    right: -25px;
    margin-left: 39px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 66px;
    margin-bottom: 63px;
    justify-content: center;
  }
  
  .activity2 {
    padding: 8px;
    margin-top: 24px;
    margin-left: 13px;
    margin-right: 13px;
  }
  
  .card.card-flush.h-xl-100 {
    border-radius: 7px;
  }
  
  .analysis {
    padding: 2px;
    margin-top: 31px;
  }
  
  .carousel-custom.carousel-stretch {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
  }
  
  .card-rounded {
    border-radius: 0.625rem;
  
    width: 382px;
  }
  
  .expiring-time {
    display: flex;
    
    column-gap: 14px;
  }
  
  .timer {
    color: #f1416c;
    font-size: 19px;
  }
  