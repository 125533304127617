.home_page-title .home-title {

    width: 718px;
    height: 156px;
    /* UI Properties */
    text-align: center;
    font: normal normal normal 45px/55px Sora;

    color: #264ACA;
    opacity: 1;
}



.home-paragarph {

    padding-top: 20px;
    width: 1096px;
    height: 170px;

    text-align: center;
    /* justify-content: center; */
    display: -webkit-inline-box;
    font: normal normal normal 20px/31px Sora;

    color: #264ACA;
    opacity: 1;

}

.homeimg {
    display: flex;
    justify-content: center;
    padding-bottom: 5rem;
}



.Knowmore span {
    width: 382px;
    height: 95px;
    text-align: center;
    font: normal normal normal 36px/50px Sora;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.need_imgresponsive {
    display: none;
}

.heading.text-centerresponsive.d-flex.justify-content-center {
    display: none;
}

.cybersecurityresponsive {
    display: none;
}

.responsive_blog {
    display: none;
}

@media screen and (max-width: 520px) {
    .home_page-title .home-title {
        font: normal normal normal 26px/33px Sora;
    }

    .home-paragarph {

        width: 74%;
        height: auto;
        font: normal normal normal 16px/27px Sora;
    }

    .need_img {
        display: none;
    }

    .text_consumed {
        display: none;
    }

    .need_imgresponsive {
        display: block;
    }
    

    #hompage-testimonials {
        padding-top: 4rem;

    }

    .cybersecurityresponsive {
        display: block;
    }

    .heading.text-center {
        display: none;
    }

    .header .navbar .navbar-brand img {
        width: 230px;
    }

    .navbar-toggler-icon {

        width: 1.5em;
        height: 2.5em;

        background-size: 173%;
    }

    .homeimg {
        padding-top: 4rem;

    }

    .h2.banner-heading {
        font: normal normal normal 33px/48px Sora !important;
    }

    .responsive_blog {
        display: block;
    }

    .blog {
        display: none;
    }

    .bottom-cta {
        /* margin-top: 17rem; */
    }

    .responsive_blog_text {
        padding-top: 4rem;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
    }

    .responsive_blog_text.blogresposnsive_title {
        color: #FF763F;
        font-size: 17px;
    }

    .thumb-wrapper {
        padding-bottom: 2rem;
    }

    .flickity-button:hover {
        background: content-box !important;
    }

    .flickity-button {
        background: content-box !important;
    }

    .button .button-rounded .border-0 {
        padding: 9px !important;
        width: 250px !important;
    }

    .knowmore {
        width: 121px !important;
    }

    .cybersecurity {
        font: 500 12px / 21px Sora !important;
    }

    .home_page-title .home-title {
        font: normal normal normal 33px/43px Sora !important;
    }

    .flickity-prev-next-button.next {
        right: 5px !important;
    }

    /* .responsive_testmonalilimgblu {
                    display: none !important;
                } */

}

@media (min-width: 521px) and (max-width: 768px) {
    .home_page-title .home-title {
        font: normal normal normal 33px/43px Sora !important;
    }

    .home-paragarph {
        font: normal normal normal 16px/27px Sora !important;
    }

    .Knowmore span {
        font: normal normal normal 21px/32px Sora !important;
    }

    .beginerr_hero {
        font: 22px / 32px Sora !important;
    }

    #course-testimonials .carousel-item p {
        font: normal normal normal 16px/31px Sora !important;
    }
}

@media (min-width: 769px) and (max-width: 820px) {
    .home_page-title .home-title {
        font: normal normal normal 33px/43px Sora !important;
    }

    .home-paragarph {
        font: normal normal normal 16px/27px Sora !important;
    }

    .Knowmore span {
        font: normal normal normal 21px/32px Sora !important;
    }

    .beginerr_hero {
        font: 22px / 32px Sora !important;
    }

    #course-testimonials .carousel-item p {
        font: normal normal normal 16px/31px Sora !important;
    }
}





@media (min-width: 821px) and (max-width: 1024px) {
    .home_page-title .home-title {
        font: normal normal normal 33px/43px Sora !important;
    }

    .home-paragarph {
        font: normal normal normal 16px/27px Sora !important;
    }

    .Knowmore span {
        font: normal normal normal 21px/32px Sora !important;
    }

    .beginerr_hero {
        font: 22px / 32px Sora !important;
    }

    #course-testimonials .carousel-item p {
        font: normal normal normal 16px/31px Sora !important;
    }
}



@media screen and (max-width: 500px) {
    .ctaRes {
        margin-top: 250px;
    }
    /* .flickity-prev-next-button.next {
        margin-top: -180px;
    }
    .flickity-prev-next-button.previous {
        margin-top: -180px;
    } */

}