.emailverfication_box{
    border-radius: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    bottom: 0;
    border: 1px solid grey;
    width: 60%;
    padding: 30px;
}

.img_section{
    
}
.email_btn button{
    background: #1b2137;
    line-height: 22px;
    font-size: 20px;
    color: white;
    
    display: inline-block;
    border-radius: 16px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    /* text-transform: uppercase; */
    transition: all ease-in-out 0.3s;
    border: 1px solid #ff763f;

    border-radius: 47px;
    text-transform: capitalize;
   
}
.emailstyle{
    display: flex;
    justify-content: center;
}




.alert_green {
    margin-top: 2rem;
    color: black;
    background-color: #7eed7e8f;
    border-color: #0080008f;
    margin: 0;
    border: 2px solid transparent;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 2px solid transparent;
    border-radius: 0.25rem;
    font-size:1.2rem !important;
    width: 59%;
  }
