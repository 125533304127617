.dimension {
    width: 500px;
    height: 300px;
}

.nav-tabs .nav-link.active {


    border-bottom: 4px solid orange;
    width: fit-content
}



.nav-tabs .nav-link {

    color: rgba(0, 0, 0, 0.75);
    ;




}

.Course_Badge {
    width: 150px;
    top:5px;
}

.nav.nav-tabs {
    display: flex;
    text-align: center;
    /* justify-content: center; */
}

.btnfollow {
    border-radius: 24px;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    background: #1b2137;
    color: white;
}

.accordion-header button {

    color: #3a3a3a;
}


.accordion {

    border-radius: 8px;
    overflow: hidden;
    /* -webkit-box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.08), 1px 0px 1px rgba(0, 0, 0, 0.08), -1px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08); */
}


/* .video_learning{
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-surface-border);
    background-color: var(--color-surface);
} */
.accordion-item {
    border-bottom: 1px solid rgba(255, 255, 255, .35);
    background-color: transparent;
    color: #1b2137;
}



.accordion-button::after {
    color: white;
}


.page-item.active .page-link {
    z-index: 3;
    background: #fff;
    color: #FF763F;
    font-size: 19px;
}

.responsive-bundletestimonal {
    display: none;
}

@media screen and (max-width: 520px) {
    .nav-tabs button {
        /* Adjust styles for tab buttons on smaller screens */
        flex: none;
    }

    .courses-tab {
        display: none;
    }

    .responsive-bundletestimonal {
        display: block;
    }


}



@media screen and (max-width: 1024px) {
    .Course_Badge {
        width: 150px;
        top:5px;
    }
    
}





/* Adjust the position and spacing of Flickity previous and next buttons */
.flickity-prev-next-button {
    width: 0px !important;

}

/* Styles for both previous and next buttons */
.flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    width: 30px !important;

    /* Adjust the width as needed */
    height: 30px !important;
    /* Adjust the height as needed */
    top: 35rem !important;
    /* Adjust the top position */
    transform: translateY(-75%) !important;
    /* background-color: grey; */
    /* Center the button vertically */
    border-radius: 50% !important;
    /* Make the button fully rounded */
    border: 1px solid #d7d7d7 !important;
    /* Add a red border */
    box-sizing: content-box;
    padding: 6px;

}

/* Styles for the previous button */
.flickity-prev-next-button.previous:hover .flickity-button-icon,
.flickity-prev-next-button.previous:active .flickity-button-icon {
    /* background-color: white !important; */
    /* Change background color on hover and active */
    border-color: #d7d7d7 !important;
    border: 2px solid #9c9c9c !important;
    /* Change border color on hover and active */
}

/* Styles for the next button */
.flickity-prev-next-button.next:hover .flickity-button-icon,
.flickity-prev-next-button.next:active .flickity-button-icon {
    /* background-color: white !important; */
    /* Change background color on hover and active */
    border-color: #d7d7d7 !important;
    border: 2px solid #9c9c9c !important;
    /* Change border color on hover and active */
}

.arrow {
    color: #9c9c9c !important;
}

.flickity-page-dots {
    display: none !important;
}

.flickity-next-button {
    top: 10px;
}

/* .button {
    border: 1px solid grey !important;
} */

/* Styles for the previous button */
.flickity-prev-next-button.previous {
    left: 25% !important;
    border-radius: 30px;
    border: 1px solid transparent !important;
    /* Adjust the left position for the previous button */
}

/* Styles for the next button */
.flickity-prev-next-button.next {
    left: 51% !important;
    border: 1px solid transparent !important;
    /* Adjust the left position for the next button */
}




.flickity-button:focus {
    box-shadow: 0 0 0 0px white !important;
}



.card__Container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .card__Container .item {
    display: flex;
    flex-direction: column;
  }
  
  .card__Container .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .card__Container .details {
    flex-grow: 1;
  }