.login_box.form-control {
    display: block;
    /* width: 100%; */

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    /* background-color: #1A2037; */
    background-clip: padding-box;
    border: none;
    /* border-radius: 14px; */

    /* padding: 18px 31px; */

}

.row.login_row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_box {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px 0px;
    padding: 2rem;
    width: 35%;
    /* height: 25vh; */


}

.login_row {
    padding-top: 0%;
    /* margin-left: 20%; */
    padding-bottom: 4%;
}





/* 
.login_btn{
    padding-top: 20px;
    padding-bottom: 20px; 
} */




/* .login_btn button{
    background: #4aa5f0;
    line-height: 22px;
    font-size: 20px;
    color: white;
    padding: 19px 44px 19px 44px !important;
    display: inline-block;
    border-radius: 16px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
   
    transition: all ease-in-out 0.3s;
    
   
} */
/* #inputFirstName{
        box-shadow: var(--shd,4px 4px rgba(11, 8, 163, 0.6))
    
    
    }   */


.form-label.form-control {
    box-shadow: var(--shd, 0 1px 4px rgba(0, 0, 0, .6))
}

.alert_danger {
    margin-top: 2rem;
    color: #b14542;
    background-color: #fec0bf;
    border-color: #feb1af;
    margin: 0;
    border: 2px solid transparent;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 2px solid transparent;
    border-radius: 0.25rem;
    font-size: 0.8rem;
}

.alert_green {
    margin-top: 2rem;
    color: black;
    background-color: #0080008f;
    border-color: #0080008f;
    margin: 0;
    border: 2px solid transparent;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 2px solid transparent;
    border-radius: 0.25rem;
    font-size: 0.8rem;
}



/* #login:hover{
    
    background-color: #ff763f ;
  } */




@media screen and (max-width: 520px) {
    .login_box {
        width: 89% !important;
    }

    .forgotclss {
        font-size: 12px !important;
    }
}


@media (min-width: 563px) and (max-width: 768px) {

    .login_box {
        width: 78% !important;
    }

    .forgotclss {
        font-size: 12px !important;
    }
}

@media (min-width: 769px) and (max-width: 820px) {
    .login_box {
        width: 78% !important;
    }

    .forgotclss {
        font-size: 12px !important;
    }

    .Login_title {
        top: 10% !important;
    }
}


@media (min-width: 821px) and (max-width:1024px) {
    .login_box {
        width: 55% !important;
    }

    .forgotclss {
        font-size: 12px !important;
    }

    .Login_title {
        top: 10% !important;
    }
}