
 
  .adduser_heading h1 {
    font-family: poppins;
    font-size: 1.3rem;
    color: #363945;
  
    font-weight: bolder;
  }
  .adduser_heading h5 {
    color: rgb(147, 149, 151);
    font-size: 1rem;
  }
  
  .adduser_main input {
    border: none;
    border-bottom: 1px solid #c3b5b5;
    border-radius: none;
    border-radius: 0;
    outline: none;
  }
  .adduser_main label {
    font-size: 0.9rem;
    font-weight: 500;
  }
  .adduser_main select {
    font-size: 0.8rem;
    outline: none;
  }

  .adduser_main button {
    text-align: center;
    width: 150px;
    margin-top: 2rem;
    text-transform: capitalize;
    font-size: 0.9rem;
    font-weight: 500;
    border-radius: 4px;
    background-color: #1e1e2d;
  }
  .adduser_main button:hover {
    text-align: center;
    width: 150px;
    margin-top: 2rem;
    text-transform: capitalize;
    font-size: 0.9rem;
    font-weight: 500;
    border-radius: 4px;
    background-color: #1e1e2d;
  }
  




  