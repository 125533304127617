.purchase-history {
    padding: 10px;
    margin-top: -7px;
    /* border-radius: 75px; */
  }
  
  .card {
    margin: 0 0.5em;
    /* box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18); */
    border: none;
    border-radius: 7px;
  }
  
