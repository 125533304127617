.ant-modal-content {
    width: 700px;
}




/* CustomModal.css */

.custom-modal {
    backdrop-filter: blur(5px);
    /* Apply the blur effect */
    background-color: rgba(0, 0, 0, 0.3);
    /* Background color with transparency */
}

.bundle-tab {
    font-size: 24px;
    background-color: white;
    margin-top: -5rem;
    padding: 0px;
}





/*section tabs*/
.bundle-tab .nav-tabs {
    border: none;
}

.bundle-tab .nav-tabs .nav-link {
    padding: 0;
    font-weight: 500;
    font-size: 20px;
    color: #000;

    margin: 0 10px;
}

.bundle-tab .nav-tabs .nav-link.active {
    border: none;

    color: white;
    background: #FF763F;
    padding: 20px;
    border-radius: 1px;

}

.bundle-tab .nav-tabs {
    justify-content: space-between;

}

.bundle-tab .tab-content {
    margin: 50px 0 50px 0;

}

.bundle-tab .nav-tabs .nav-link {
    background: #F2F2F2;
    padding: 20px;
    border: none;
    border-radius: 1px;
}

.bundle-left-sec {
    /* border-right: 2px solid #FF763F; */
    font-size: 18px;
    line-height: 35px;
}

.bundle-left-sec .heading-blue {
    font: normal normal normal 35px/55px Sora;
    color: #264ACA;
}

.bundle-right-sec {
    border-left: 2px solid #FF763F;
    font-size: 18px;
    line-height: 35px;
}

.bundle-right-sec .heading-orange {
    font: normal normal normal 25px/45px Sora;
    color: #FF763F;
}

.heading-icon {
    align-items: flex-start;
    justify-content: flex-start;
}

.feature-icon {
    width: 50px;
}

.feature-content {
    padding-left: 10px;
    padding-bottom: 20px;
}

.button.btn-blue {
    background: #264ACA  !important;
    color: #fff;
    padding: 0px 15px;
    border-radius: 25px;
    font-size: 13px;
}

.button.btn-blue:hover {
    background: #FF763F !important;
}
.button.btn-blue.btn-primary:hover {
    background-color: #FF763F !important;
}
.BundleDeytails .btn-blue {
    background: #FF763F;
    padding: 10px 25px;
    font-size: 20px;
}

.BundleDeytails .btn-blue:hover {
    background: #264ACA;
}

.BundleDeytails h3 {
    display: none;
}









@media screen and (max-width: 520px) {
    .bundles.banner-title {
        font-size: 30px !important;
        width: 100% !important;
    }


    .bundle-tab .nav-tabs .nav-link.active {
        border: none;


        padding: 5px !important;

        font-size: 12px !important;
    }

    .bundle-tab .nav-tabs .nav-link {
        font-size: 12px !important;
        margin-bottom: 2rem !important;
        padding: 7px !important;
    }

    .bundle-left-sec .heading-blue {
        font-size: 26px;
    }

    .bundle_detailsprag {
        font: normal normal normal 16px/28px Sora;
    }

    .bundle-tab {
        margin-top: 0px !important;
    }

    .banner-title {
        color: white;
        font-size: 20px !important;
    }

    .BundleDeytails {

        top: 27% !important;
        left: 49% !important;


    }

    .button {
        font-size: 15px !important;
    }

    .custom-modal {
        width: 100vw;
        overflow-x: auto;
    }


}


@media (min-width: 521px) and (max-width: 768px) {



    .bundles.banner-title {
        font-size: 26px !important;
    }

    .bundle-tab {
        margin-top: 0px !important;
    }

    .bundle-tab .nav-tabs {
        justify-content: space-between !important;
    }

    .bundle-tab .nav-tabs .nav-link {
        font-size: 15px !important;

        margin: 0 10px !important;
        margin-top: 1rem !important;
    }

    .BundleDeytails {
        top: 15% !important;
        left: 48% !important;
    }

    .banner-title {
        font-size: 30px !important;
        margin-top: -190px;
    }

    .BundleDeytails .btn-blue {
        font-size: 10px !important;
    }

    .bundlecourse {
        top: 13% !important;
        left: 51% !important;
        width: 100%;
    }

    .ant-modal.css-dev-only-do-not-override-gzal6t {
        width: auto !important;
    }
}

@media (min-width: 769px) and (max-width: 820px) {
    .bundles.banner-title {
        font-size: 26px !important;
    }

    .bundle-tab {
        margin-top: 0px !important;
    }

    .bundle-tab .nav-tabs {
        justify-content: space-between !important;
    }

    .bundle-tab .nav-tabs .nav-link {
        font-size: 15px !important;

        margin: 0 10px !important;
        margin-top: 1rem !important;
    }

    .BundleDeytails {
        top: 13% !important;
        left: 48% !important;
    }

    .banner-title {
        font-size: 26px !important;
    }

    .BundleDeytails .btn-blue {
        font-size: 10px !important;
    }

    .bundlecourse {
        top: 14% !important;
        left: 37% !important;
    }

    .ant-modal.css-dev-only-do-not-override-gzal6t {
        width: auto !important;
    }
}


@media (min-width: 769px) and (max-width: 1024px) {
    .bundles.banner-title {
        font-size: 26px !important;
    }

    .bundle-tab {
        margin-top: 0px !important;
    }



    .bundle-tab .nav-tabs .nav-link {
        font-size: 9px !important;

        margin: 0 11px !important;
        font-weight: 600 !important;

    }

    .BundleDeytails {
        top: 13% !important;
        left: 48% !important;
    }

    .banner-title {
        font-size: 30px !important;
        margin-top: -190px;
    }

    .BundleDeytails .btn-blue {
        font-size: 10px !important;
    }

    .bundlecourse {
        top: 14% !important;
        left: 37% !important;
    }

    .ant-modal.css-dev-only-do-not-override-gzal6t {
        width: auto !important;
    }
}

@media (min-width: 820px) and (max-width: 1180px) {
    .banner-title {
        font-size: 30px !important;
        margin-top: 10px;
    }
}


@media (min-width: 1024px) and (max-width: 1366px) {
    .banner-title {
        font-size: 30px !important;
        margin-top: 20px;
    }
}
@media screen and (max-width: 412px) {
    .banner-title {
        margin-top: -70px;
    }
}


@media screen and (max-width: 360px) {
    .banner-title {
        margin-top: -20px;
    }
}


@media screen and (max-width: 430px) {
    .banner-title {
        margin-top: -75px;
    }
}


@media screen and (max-width: 390px) {
    .banner-title {
        margin-top: -50px;
    }
}


@media screen and (max-width: 375px) {
    .banner-title {
        margin-top: 0px;
    }
}

