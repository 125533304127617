.custom-row {
    display: flex;
}

.custom-column {
    flex: 1;
    padding: 10px;
}

.taller-column {
    height: 300px;
    /* You can adjust the height value as needed */
}

.taller-card {
    height: 410px;
    /* Adjust the height as needed */
}



.custom-date-picker {
    padding: 5px;
    border-radius: 7px;
    border: 1px solid rgb(218, 218, 218);
}


.table-row {
    border-bottom: 1px solid #ccc;
    /* Adjust the style as needed */
}


/* Adjust the z-index of ApexCharts toolbar */
.apexcharts-toolbar {
    z-index: auto !important;
}

/* Or, to remove z-index altogether */
.apexcharts-toolbar {
    z-index: initial !important;
}


.alluser_admin {
    display: flex;
    justify-content: space-between;
    /* Adjust the spacing between the items */
}

.alluser_cards,
.totalcourse_dash,
.totalbandles_dash {
    flex: 1;
    margin-right: 10px;
    /* Adjust the margin between the items */
}

/* @media (max-width: 845px) and (min-width: 320px) {
    .card.card-flush .userlisth.h-md-100.mb-5.mb-xl-10 {
        width: 131%;
    }

    .card-header {
        width: 100%;
    }
}


.p-datatable-table {
    width: auto !important;
} */

.responsive_arrow {
    display: none !important;
}

@media screen and (max-width: 520px) {
    .dashboards_userlist {
        width: 100% !important;
    }

    .dashboards_userlistUserCount {
        width: 100% !important;
    }

    .p-multiselect-label-container {
        width: 100% !important;
    }

    .map_resposnive {
        padding-top: 2rem !important;
    }

    .app-container {
        max-width: none !important;
        padding-left: 2px !important;
        padding-right: -2px !important;
    }

    .corse_chart {
        flex-direction: column !important;
        gap: 2rem !important;
    }

    .topselling_course {
        flex-direction: column !important;
        gap: 2rem !important;
    }

    .responsive_arrow {
        display: block !important;
        margin-top: -8px;
        margin-bottom: 20px;
    }

    .slect_course_lable {
        width: 320px !important;
    }


    .p-datatable {


        overflow-x: auto !important;
    }

    .p-datatable-wrapper {
        width: fit-content !important;
    }





}


@media (min-width: 563px) and (max-width: 768px) {
    .adminwlcom {
        width: 100% !important;
    }

    .alluser_admin {

        flex-direction: column !important;

    }

    /* .alluser_cards {
        width: 77% !important;
    }

    .totalcourse_dash {
        width: 77% !important;
    }

    .totalbandles_dash {
        width: 77% !important;
    } */

    .admin_dash_res {
        flex-direction: column !important;
    }

    .user_count_dash {
        flex-direction: column !important;
    }

    .totaluser_dash {
        width: 100% !important;
    }

    /* .sales_overview {
        width: 52% !important;
    } */

    /* .studentlist {
                                    width: 52% !important;
                                } */

    .corse_chart {
        flex-direction: column !important;
        gap: 2rem !important;
    }

    .topselling_course {
        flex-direction: column !important;
        gap: 2rem !important;
    }

    .col-md-6 {
        width: 100% !important;
    }

    .col-6 {
        width: 100% !important;
    }

    .rmdp-day-picker .rmdp-full-year {
        grid-template-columns: 1fr 1fr !important;
        padding-left: 0px !important;
    }

    .card .p-datatable .p-component .p-datatable-responsive-scroll {
        overflow-x: auto;
        /* or overflow-x: scroll; depending on your preference */
    }

    .p-datatable {


        overflow-x: auto !important;
    }

    .p-datatable-wrapper {
        width: fit-content !important;
    }


}



@media (min-width: 769px) and (max-width: 820px) {
    .adminwlcom {
        width: 100% !important;
    }

    .alluser_admin {

        flex-direction: column !important;

    }

    /* .alluser_cards {
            width: 77% !important;
        }
    
        .totalcourse_dash {
            width: 77% !important;
        }
    
        .totalbandles_dash {
            width: 77% !important;
        } */

    .admin_dash_res {
        flex-direction: column !important;
    }

    .user_count_dash {
        flex-direction: column !important;
    }

    .totaluser_dash {
        width: 100% !important;
    }

    /* .sales_overview {
            width: 52% !important;
        } */

    /* .studentlist {
                                        width: 52% !important;
                                    } */

    .corse_chart {
        flex-direction: column !important;
        gap: 2rem !important;
    }

    .topselling_course {
        flex-direction: column !important;
        gap: 2rem !important;
    }

    .col-md-6 {
        width: 100% !important;
    }

    .col-6 {
        width: 100% !important;
    }

    .rmdp-day-picker .rmdp-full-year {
        grid-template-columns: 1fr 1fr !important;
        padding-left: 0px !important;
    }

    .p-datatable {


        overflow-x: auto !important;
    }

    .p-datatable-wrapper {
        width: fit-content !important;
    }
}






@media (min-width: 821px) and (max-width: 1024px) {
    .admin_dash {
        width: 100% !important;
    }

    .alluser_admin {

        flex-direction: column;

    }

    .admin_dash,
    .totaluser_dash,
    .col-md-6 {
        width: 100%;
    }

    .totaluser_dash {
        width: auto !important;
    }

    .margin {
        margin-top: 40px;
    }

    .rmdp-day-picker .rmdp-full-year {
        grid-template-columns: 1fr 1fr !important;
        padding-left: 0px !important;
    }

    .p-datatable {


        overflow-x: auto !important;
    }

    .p-datatable-wrapper {
        width: fit-content !important;
    }
}


@media(min-width: 1024px) and (max-width: 1024px) {
.admin_card {

    width: 100% !important;
}
}






@media screen and (max-width: 768px) {
    .adminwlcom {
        width: 100%;
    }

    .alluser_admin {

        flex-direction: column;

    }

}

@media (min-width: 1025px) and (max-width: 1300px) {
    .admin_dash {
        width: 100% !important;
    }

    .alluser_admin {

        flex-direction: column;

    }

    .admin_dash,
    .totaluser_dash,
    .col-md-6 {
        width: 100% !important;;
    }
    .col-lg-6 {
        width: 100% !important;;
    }

    .totaluser_dash {
        width: auto !important;
    }
    .margin {
        margin-top: 40px;
    }
}

@media (max-width: 1024px) {
    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}

