.card {
  margin: 0 0.5em;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
  border: none;
  border-radius: 7px;
  /* width: 113%; */
  width: 100%;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 100px;
  border-radius: 0.475rem;
  padding: 0.65rem 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f7f7;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}





.ant-modal.css-dev-only-do-not-override-12upa3x {
  width: auto !important;
}

.booked {
  color: red;
}





.rmdp-range:has(.reserved) {
  background-color: green;
  pointer-events: none;
}




/* .rmdp-range.custom-calendar.alluserlist {
  background-color: green !important;
  box-shadow: 0 0 3px #8798ad;
  color: #fff;
} */



.custom-calendar.alluserlist .rmdp-range {
  background-color: green !important;
  ;
  /* Change the background color to green */

}



.ant-modal-content {
  width: 700px;
}


/* Assuming you have a CSS class called date-picker */
.date-picker.react-datepicker__day--selected {
  background-color: green;
  /* Change the background color */
  color: white;
  /* Change the text color */
}



ant-picker-cell-inner {
  background-color: green;
  /* Change the background color */
  color: white;
  /* Change the text color */
}

.resfilter_btn{
  margin-top: 10px;
  margin-left: -5px;
}

.csv_btn .pi-upload {
  margin-right: 8px; 
}



/* @media screen and (max-width: 500px) {
  .filter_btn {
  margin-top: 10px;
  }
  
} */

@media screen and (max-width: 412px) {
  .resfilter_btn {
  display: flex;
  }
  
}

/* @media screen and (max-width: 1024px) {
  .filter_btn {
  margin-top: 10px;
  }
  
} */

/* @media screen and (max-width: 1500px) {
  .filter_btn {
  margin-top: 10px;
  }
  
} */