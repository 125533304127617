/* .accordion-item{
    z-index: 99999999 !important;
} */


.cyber-consultancy1 {
    position: relative;
}

.cyber-consultancy1 img {

    left: 219px;
    width: 339px;
    height: 533px;
}



.cyber-consultancy2 {
    position: relative;
    top: 100px;
}

.cyber-consultancy2 img {

    left: 733px;
    width: 455px;
    height: 616px;
}

.cyber-consultancy3 {
    position: relative;
}

.cyber-consultancy3 img {

    left: 1293px;
    width: 440px;
    height: 567px;
}

.Consultancypageresponsive {
    display: none;
}

@media screen and (max-width: 520px) {
    .you-know h2 {
        font-size: 30px !important;
        font-weight: 400 !important;
    }

    .consultancy_page_praph {
        padding: 0px;
        margin: 0px;
    }

    .row.consultancy {
        display: none !important;
    }


    .Consultancypage {
        display: none;
    }

    .Consultancypageresponsive {
        display: block;
    }

    .why-cyber h2 {
        font-size: 34px !important;
    }

    .consultancy_page_praph {
        width: 357px !important;
        font-size: 16px;
    }

    .why-cyber .wrapper h3 {
        font-size: 24px !important;
        font-weight: 500;
    }

    .wrapper_text {
        width: 100%;
        font-size: 16px !important;
    }

    .wrapper_stay h3 {
        font-size: 28px !important;
    }

    .our-service .accordion {
        flex-direction: column;
    }

    .our-service .accordion {
        font-size: 29px !important;
    }

    .our-service h2 {
        font-size: 28px !important;
    }

    .our-service .accordion .content {
        width: 100% !important;
    }

    .our-service .accordion .content .accordion-button {
        width: 100%;
        font-size: 22px;
        padding: 10px;
    }

    .our-service .accordion .content .accordion-collapse p {


        font-size: 16px !important;
        width: 445px;
    }

    .accordion-button {
        width: 376px;
    }

    .why-cyber .wrapper {
        /* padding: 10px; */
    }

    .img-wrapper {
        padding-bottom: 20px;
    }

    .why-cyber.text-center {
        padding-top: 50px;
    }

    .our-service h2 {
        font-weight: 500;
    }

}












@media (min-width: 563px) and (max-width: 768px) {
    .cyber-consultancy1 img {
        width: 200px !important;
        height: auto !important;
    }

    .cyber-consultancy2 img {
        width: 200px !important;
        height: auto !important;
    }

    .cyber-consultancy3 img {
        width: 200px !important;
        height: auto !important;
    }

    .you-know h2 {
        font-size: 37px !important;
    }

    .why-cyber h2 {
        font-size: 37px !important;
    }

    .consultancy_page_praph {
        font-size: 16px !important;
    }

    .why-cyber .wrapper .count span {
        font-size: 31px !important;
    }

    .wrapper_text {
        font-size: 13px !important;
    }

    .our-service h2 {
        font-size: 30px !important;
    }

    .our-service .accordion .content .accordion-button {

        font-size: 26px !important;
    }

    .our-service .accordion .content .accordion-collapse p {
        font-size: 14px !important;
    }

    .why-cyber .wrapper h3 {
        font-size: 16px !important;
    }


}

@media (min-width: 769px) and (max-width: 820px) {
    .cyber-consultancy1 img {
        width: 200px !important;
        height: auto !important;
    }

    .cyber-consultancy2 img {
        width: 200px !important;
        height: auto !important;
    }

    .cyber-consultancy3 img {
        width: 200px !important;
        height: auto !important;
    }

    .you-know h2 {
        font-size: 37px !important;
    }

    .why-cyber h2 {
        font-size: 37px !important;
    }

    .consultancy_page_praph {
        font-size: 16px !important;
    }

    .why-cyber .wrapper .count span {
        font-size: 31px !important;
    }

    .wrapper_text {
        font-size: 13px !important;
    }

    .our-service h2 {
        font-size: 30px !important;
    }

    .our-service .accordion .content .accordion-button {

        font-size: 26px !important;
    }

    .our-service .accordion .content .accordion-collapse p {
        font-size: 14px !important;
    }

    .why-cyber .wrapper h3 {
        font-size: 16px !important;
    }

    .focus_business {
        top: 15rem !important;
    }
}


@media (min-width: 821px) and (max-width:1024px) {
    .cyber-consultancy1 img {
        width: 200px !important;
        height: auto !important;
    }

    .cyber-consultancy2 img {
        width: 200px !important;
        height: auto !important;
    }

    .cyber-consultancy3 img {
        width: 200px !important;
        height: auto !important;
    }

    .you-know h2 {
        font-size: 37px !important;
    }

    .why-cyber h2 {
        font-size: 37px !important;
    }

    .consultancy_page_praph {
        font-size: 16px !important;
    }

    .why-cyber .wrapper .count span {
        font-size: 31px !important;
    }

    .wrapper_text {
        font-size: 13px !important;
    }

    .our-service h2 {
        font-size: 30px !important;
    }

    .our-service .accordion .content .accordion-button {

        font-size: 26px !important;
    }

    .our-service .accordion .content .accordion-collapse p {
        font-size: 14px !important;
    }

    .why-cyber .wrapper h3 {
        font-size: 16px !important;
    }

    .focus_business {
        top: 15rem !important;
    }
}