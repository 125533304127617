.nav-pills .nav-link.active {
    color: var(--bs-nav-pills-link-active-color);
    background-color: #ff763f;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    height: 41px;
    border-radius: 4px;
}

.nav-pills .nav-link {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 700;
    font-size: 14px;

    border-radius: 4px;
}