.sub-banner-outer h1 {
    color: #4aa5f0;
    animation: color-animation 4s linear infinite;
    font-size: 50px;
    text-align: center;
}


.banner-container-box {
    width: 100%;
    margin-top: -10%;
    padding: 6% 5px;


    background-size: cover;
    /* background-color: #1b2137; */
}


.banner-btn.discover-btn-banner {
    text-align: center;
    /* position: relative; */
    padding-top: 30px;

}


.btn-inner {

    background: #1A2037;
    line-height: 22px;
    font-size: 20px;
    color: white;
    padding: 16px 38px 17px 34px !important;
    display: inline-block;
    border-radius: 20px;
    font-weight: 600;
    text-decoration: none;

    /* transition: all ease-in-out 0.3s; */
    border: 1px solid white;
}


.signUp_section {
    /* padding-top: 100px; */
    padding-bottom: 100px;
}

/* .container{.contact .form-select
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
    background-color: #F9F9F9;
    padding-bottom: 50px;
} */
.course_details_section h3{
    color:#264aca
}

.banner-btn .discover-btn-banner .btn-inner i {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    font-family: 'FontAwesome';
}

.fa-angles-right:before {
    content: "\f101";
    padding: 6px;
}


/* .alert_danger ::{
display: inline;
content: "⚠ ";
} */
.alert_danger {
    margin-top: 2rem;
    color: #b14542;
    background-color: #fec0bf;
    border-color: #feb1af;
    margin: 0;
    border: 2px solid transparent;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 2px solid transparent;
    border-radius: 0.25rem;
    font-size: 0.8rem;
}



.contact .form-control {
    background-clip: padding-box;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    height: 45px;
    line-height: 20px;
    padding: 10px;
    width: 100%;
}

.contact .form-select {

    background-clip: padding-box;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    height: 45px;
    line-height: 20px;
    padding: 10px;
    width: 100%;
}

/* .sign_up_form {
    padding-top: 30px;
} */

.form-btn {
    /* text-align: center; */
    padding-top: 40px;
}

.form-btn button {
    background: #4aa5f0;
    line-height: 22px;
    font-size: 20px;
    color: white;
    padding: 19px 44px 19px 44px !important;
    display: inline-block;
    border-radius: 16px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    /* text-transform: uppercase; */
    transition: all ease-in-out 0.3s;
    border: 1px solid #4aa5f0;
}




/* checkout */


.text h6 {
    color: orange;
}

.course_include_text p {
    font-size: 17px;
    font-weight: 400;
}

.course_doller {
    color: orange;
    /* font-size: 40px; */
    padding: 20px;
    /* height: 20vh; */
    width: 77%;
    background-color: white;

    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.pyment_text {
    text-align: left;
    color:#264aca;
}

.course-btn {
    display: flex;
    gap: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.course-btn button {
    background: #4aa5f0;
    line-height: 15px;
    font-size: 20px;
    color: white;

    display: inline-block;
    border-radius: 16px;
    font-weight: 400;
    text-decoration: none;
    text-align: center;
    text-transform: lowercase;

    transition: all ease-in-out 0.3s;
    border: 1px solid #4aa5f0;
}

.right_course {
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
    background-color: #F9F9F9;
    padding: 30px;
    padding-top: 40px;
    /* width: 32%; */
    border-radius: 8px;

}

.btn .btn-primary {
    text-transform: capitalize;
}

/* .contact .form-control, .contact .form-select {
    background-clip: padding-box;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    height: 45px;
    line-height: 20px;
    padding: 10px;
    width: 100%;
} */

.form-label {
    margin-top: 15px;
}



.loginandpay {}






/* .container {
    position: relative;
  }
   */
.loader-container {

    /* transform: translate(-10%, -50%); */
    z-index: 1;

    border-radius: 8px;
    padding: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
}

.blur-form {
    filter: blur(5px);
    /* Adjust the blur radius as needed */

}




.form-container {
    z-index: 10;

}







.css-b62m3t-container {
    font-size: 15px;
    /* border: 1px solid grey; */
    border-radius: 5px;

}











.card_billing {
    /* background: #fff;
    border-radius: 2px;
    display: inline-block;
    height: 352px;
    margin: 1rem;
    position: relative;
    width: 500px; */
}



.course_dollerr {

    color: #FF763F;
    /* font-size: 40px; */
    padding: 15px;
    /* height: 20vh; */
    width: 80%;
    background-color: white;
    border: 1px solid #FF763F;
    border-radius: 10px;

}


.right_course {
    margin-top: 1rem;
}



.user_alredyregister {
    display: flex;
}


.user_alreadyregister_btn {
    /* width: 7%;
    background: #FF763F;

    padding: 5px; */
}

.proceed_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    width: 10%;
}

@media screen and (max-width: 520px) {
    .card_billing {
        /* width: 93%; */
    }

    .user_alredyregister {
        display: flex;
        justify-content: space-between;
    }

    .userallredy {
        font-size: 16px !important;
    }

    .user_alreadyregister_btn {
        /* width: 28%;
        background: #FF763F;

        padding: 5px; */
    }

    .course_details_section {
        margin-top: 5rem;
    }

    .proceed_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2rem;
        width: 30%;
    }
}