.services {
	/* margin-top: 23rem; */
	/* background-color: #1b2137; */
	padding-bottom: 5rem;

}



.section-title {
	text-align: center;
	padding-bottom: 2rem;
}





.services-list {
	row-gap: 2rem;


}

.services-itemm {

	padding: 2.4rem;
	color: white;
	background-color: white;




	border: 1px solid #f3f3f3;

	border-radius: 10px;
	overflow: hidden;
	border: 1px solid rgb(0 0 0 / 50%);

}

.services-itemm-img {
	margin-bottom: 0.8rem;
}

.services-itemm-img img {
	width: 100%;

}

.services-itemm-text .text {
	max-width: 460px;
}



.tour-price {
	color: hsl(205, 78%, 60%);
	/* background: hsl(206, 47%, 85%); */
	padding: 0.25rem 0.5rem;
	border-radius: hsl(205, 78%, 60%);
}




@media screen and (min-width: 768px) {
	.services-list {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 2rem;
	}

	.crriculum.col-md-6 {
		width: 50% !important;
	}
}


@media screen and (min-width: 992px) {
	.services-list {
		grid-template-columns: repeat(3, 1fr);
	}
}






.course_sectiontext h1 {
	/* Layout Properties */

	width: 467px;

	/* UI Properties */
	text-align: left;
	font: normal normal normal 40px/61px Sora;
	letter-spacing: 0px;
	color: #264ACA;
	opacity: 1;
}


/* .ui_img li {
    top: 819px;
    left: 865px;
    width: 36px;
    height: 36px;

    background: transparent url('../../PrivateImage/img/Group.png') 0% 0% no-repeat padding-box;
    opacity: 1;
} */


ul.sectionimg {




	background: transparent url('../../PrivateImage/img/Group.png') 0% 0% no-repeat padding-box;
	list-style-type: none;


}

.section_imgtext {


	font: normal normal normal 18px/36px Sora;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
}


li.textli {
	display: flex;
	align-items: start;
	margin-left: 20px;
}


.section2 h3 {
	/* top: 1328px; */
	/* left: 706px; */
	/* width: 508px; */
	height: 30px;
	font: normal normal 600 24px/78px Sora;
	letter-spacing: 1.44px;
	color: #FF763F;
	opacity: 1;
	margin-bottom: 15px
}

.section2_firsttext {

	left: 168px;
	width: 796px;
	height: 63px;
	/* UI Properties */
	text-align: center;
	font: normal normal normal 50px/60px Sora;
	letter-spacing: 0px;
	color: #000000;
	margin-bottom: 20px;
}

.section3_firsttext {
	top: 70px;
	left: 0.0999755859375px;
	width: 1132px;
	height: 51px;
	text-align: center;
	font: normal normal normal 40px/60px Sora;
	letter-spacing: 0px;
	color: #000000;
}


.Certifiedcoursetext {

	/* UI Properties */

	font: normal normal normal 50px/60px Sora;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
	margin-bottom: 3rem;
	padding-top: 5rem;
}



@media screen and (max-width: 1680px) {

	html,
	body {
		font-size: 20px;
	}

	section {
		padding: 60px 0;
	}

	.heading {
		margin-bottom: 60px;
	}

	.heading span {
		font-size: 20px;
	}

	.heading h2 {
		font-size: 36px;
	}

	.container {
		max-width: 1380px;
	}

	.button {
		font-size: 12px !important;
		margin-left: 20px;
		/* width: 30% */
	}



	.navbar-nav .nav-item .nav-link {
		margin: 0 20px;
		font-size: 18px;
		width: fit-content
		;
	}

	.header {
		margin-bottom: 20px;
	}

	.real-expert h2 span {
		font-size: 36px;
	}

	.real-expert h2 {
		font-size: 52px;
		margin-bottom: 80px;
	}

	.real-expert .col h4 {
		font-size: 32px;
	}

	.courses-tab .tab-content {
		margin-top: 50px;
	}

	.course-grid .details {
		padding: 25px;
	}

	.course-grid .item {
		padding: 15px;
	}

	.course-grid .details h5 {
		font-size: 15px;
		margin-bottom: 20px;
	}

	.course-grid .details .list-item {
		font-size: 16px;
	}

	.course-grid .details .list-item img {
		width: 16px;
	}

	.course-grid .button-group .button {
		font-size: 16px;
	}

	.course-grid .button-group .white img {
		width: 20px;
	}

	.testimonial h5 {
		font-size: 20px;
		margin-bottom: 30px;
	}

	.testimonial .slide span {
		font-size: 20px;
	}

	.testimonial .slide label {
		font-size: 18px;
	}

	.blog-grid .list-item {
		padding: 0 15px;
	}

	.blog-grid .list-item h6 {
		margin-top: 20px;
		font-size: 20px;
	}

	/* .footer .brand {
		max-width: 320px;
	} */

	.copyright p {
		font-size: 14px;
	}

	.copyright p a {
		margin: 0 20px;
	}

	.socai-icons li a {
		width: 25px;
	}

	.footer {
		padding-top: 90px;
	}

	.copyright {
		padding: 15px 0;
		margin-top: 120px;
	}
}




@media screen and (max-width: 1100px) {
	.button {
		font-size: 16px;
		padding: 10px 6px !important;
		width: 150px

	}

	.heading {
		margin-bottom: 40px;
	}

	.header .navbar {
		padding: 10px;
	}

	.header .navbar .navbar-brand {
		max-width: 180px;
	}

	.navbar-nav .nav-item .nav-link {
		font-size: 14px;
		padding: 0 7px;
	}

	.real-expert h2 span {
		font-size: 26px;
	}

	.real-expert h2 {
		font-size: 36px;
		margin-bottom: 60px;
	}

	.real-expert .col {
		padding: 0 20px;
	}

	.real-expert .col h4 {
		font-size: 20px;
		margin-bottom: 5px;
	}

	.home-paragarph {
		width: 100%
	}

	.brands-collabs {
		padding: 0;
	}

	.brands-collabs ul li {
		padding: 10px 20px;
		margin: 0 20px;
	}

	.courses-tab .nav-tabs {
		justify-content: center;
	}

	.courses-tab .nav-tabs .nav-link {
		border-bottom: 2px solid #fff;
		/* padding: 0 10px; */
		font-size: 16px;
	}

	.courses-tab .nav-tabs .nav-link.active {
		border-bottom: 2px solid #FF763F;
	}

	.courses-tab .tab-content {
		margin-top: 20px;
	}

	.course-grid .button-group .button {
		font-size: 12px;
		padding: 14px 10px;
	}
}


@media screen and (max-width: 991px) {
	.course-grid .item {
		padding: 15px 10px;
	}

	.course-grid .image-wrapper {
		height: 200px;
	}

	.course-grid .details {
		padding: 20px;
		word-break: break-all;
	}

	.course-grid .details h5 {
		font-size: 20px;
		margin-bottom: 10px;
	}

	.footer {
		padding-top: 60px;
	}

	/* .footer .brand {
		max-width: 220px;
	} */

	.copyright {
		margin-top: 50px;
	}

	.navbar-nav .nav-item .nav-link {
		padding: 10px 0;
	}

	.header .navbar .navbar-collapse .button {
		margin-top: 5px;
		display: inline-block;
		width: 100px;
        text-align: center;
	}

	.navbar-toggler:focus {
		text-decoration: none;
		outline: none;
		box-shadow: none;

	}

	.navbar-toggler {
		border: 1px solid white;
		background: white !important;
	}

	.header .navbar .navbar-collapse {
		position: absolute;
		top: 100%;
		background: #000;
		left: 0;
		right: 0;
		padding: 0 20px 20px 20px;
	}
}


@media screen and (max-width: 767px) {
	.real-expert .col {
		padding: 0 10px;
	}

	.brands-collabs ul li {
		padding: 0;
		margin: 0 30px;
	}

	.course-grid .item {
		width: 50%;
	}

	.courses-tab .nav-tabs .nav-link {
		margin-bottom: 10px;
	}

	.blog-grid .list-item {
		width: 50%;
		margin-bottom: 20px;
	}

	.footer .brand {
		margin-bottom: 20px;
	}

	.footer ul {
		margin-bottom: 30px;
	}

	.copyright {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}

	.copyright p {
		text-align: center;
	}

	.footer .socai-icons {
		margin: auto;
	}
}

@media screen and (max-width: 520px) {
	section {
		padding: 40px 0;
	}

	.header.fixed .navbar {
		padding: 15px;
	}

	.real-expert .justify-content-center {
		flex-wrap: wrap;
	}

	.real-expert .col {
		flex: 0 100%;
	}

	.course-grid .item {
		width: 100%;
	}

	.courses-tab .tab-content {
		margin-top: 0px;
	}

	.blog-grid .list-item {
		width: 100%;
	}
}


.responsive_testmonalil {
	display: none;
}

@media screen and (max-width: 520px) {
	.container {
		width: auto;
		/* Use 100% width for smaller screens */

	}

	.row {
		flex-direction: column;
		/* Stack columns vertically on smaller screens */
		/* text-align: center; */
		/* Center text within columns */
	}

	.col-md-6 {
		width: 100%;





	}

	#course-testimonials .carousel-item p,
	#course-testimonials .carousel-item span,
	#course-testimonials .carousel-item label {
		font: normal normal normal 16px/24px Sora !important;
	}

	/* .container-fluid {
									padding-top: 4rem;
								} */

	.testimonial-main {
		background-image: none;
		/* Remove background image on smaller screens */
		height: auto;
		/* Adjust height for smaller screens */
	}

	.testi-heading {
		font: normal normal 600 17px/56px Sora !important;
		margin-top: 40px;
	}

	#testimonial .carousel-item p,
	#testimonial .carousel-item span,
	#testimonial .carousel-item label {
		font: normal normal 400 16px/26px Sora !important;
	}

	.Certifiedcoursetext {
		font: normal normal normal 26px Sora;
	}


	.brands-collabs ul {


		flex-direction: column;
		align-items: center;
	}

	.brands-collabs ul li {
		width: 60%;
		padding-top: 30px;
	}

	.course_sectiontext h1 {
		width: 329px;
		text-align: center;
		font: normal normal normal 26px/34px Sora;
		letter-spacing: 0px;
		color: #264ACA;
		opacity: 1;
		padding-bottom: 30px;
	}

	.sectionimg {
		margin-bottom: 1rem;

	}

	.section2 {
		padding-top: 4rem;

	}

	.section2 h3 {
		font-size: 1.5rem;
		letter-spacing: 0px;
	}

	.section2_firsttext {
		font: normal normal normal 22px/12px Sora;
	}

	.course_sectiontext {
		display: flex;
		justify-content: center;
	}

	.section3_firsttext {
		font: normal normal normal 21px/11px Sora;

	}

	.section_imgtext {
		text-align: start;
	}

	ul.sectionimg {
		background-size: 5%;
	}

	.section_imgtext {
		font: normal normal normal 16px/30px Sora;
	}

	.responsive_testmonalilimgblu {
		position: relative;
		left: -17rem;
		width: 56%;
		top: -10rem;
	}

	.testimonial-main h5 {
		margin-top: 0rem !important;
	}

	.responsive_testmonalil {
		display: block;
	}

	.cybersecuirty {
		font-size: 12px !important;
	}

	.desktop_testimonal {
		display: none !important;

	}
}

@media (min-width: 563px) and (max-width: 768px) {
	.course_sectiontext h1 {
		font: normal normal normal 35px/50px Sora !important;
	}

	.section2_firsttext {
		font: normal normal normal 26px/36px Sora !important;
	}

	.section3_firsttext {
		font: normal normal normal 18px/52px Sora !important;
	}

	.section2 h3 {
		font: normal normal 600 16px/101px Sora !important;
	}

	.Insidebuy_course {
		width: auto !important;
	}
}


@media (min-width: 769px) and (max-width: 820px) {
	.course_sectiontext h1 {
		font: normal normal normal 35px/50px Sora !important;
	}

	.section2_firsttext {
		font: normal normal normal 26px/36px Sora !important;
	}

	.section3_firsttext {
		font: normal normal normal 18px/52px Sora !important;
	}

	.section2 h3 {
		font: normal normal 600 16px/101px Sora !important;
	}

	.Insidebuy_course {
		width: auto !important;
	}
}


@media (min-width: 821px) and (max-width:1024px) {
	.course_sectiontext h1 {
		font: normal normal normal 35px/50px Sora !important;
	}

	.section2_firsttext {
		font: normal normal normal 26px/36px Sora !important;
	}

	.section3_firsttext {
		font: normal normal normal 18px/52px Sora !important;
	}

	.section2 h3 {
		font: normal normal 600 16px/101px Sora !important;
	}

	.Insidebuy_course {
		width: auto !important;
	}
}


@media screen and (max-width: 820px){
    .course_category {
      width: 100%;
    }

  }


  @media screen and (max-width: 1024px){
    .course_category {
      width: 100%;
    }

	.col-md-8 {
		width: 100%;
	}
  }


  @media screen and (max-width: 1300px){
    .course_category {
      width: 100%;
    }

	.col-md-8 {
		width: 100%;
	}
  }