.parent_main_bg {
    background-color: white;
    width: 100%;
    /* height: 100vh; */
    opacity: 0.9;
}

.compy_heading {
    text-align: center;
}

.compy_heading h4 {
    color: #357fef;
    font-size: 18px;
}

.parent_container {
    position: relative;


}

.parent_div_section::before {
    content: "";
    display: block;
    width: 4px;

    height: 100%;
    position: absolute;
    left: 50%;

    background: #e4e4e4;

}

.parent_section_row {
    position: relative;
}

.pernal_learningimg_left::before {
    top: 50%;
    content: "";
    width: 300px;
    height: 4px;
    left: 56%;
    background-color: #3b86f8;
    position: absolute;

}

.comprhensive_content_right::before {
    top: 50%;
    content: "";
    width: 300px;
    height: 4px;
    right: 52%;
    background-color: #e4e4e4;
    position: absolute;

}

/* dot and bordersection_right */


.parent_outer_border::before {
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    left: 1px;
    top: 159px;
    border: 1px solid #e4e4e4;
}

.parent_dot::before {
    content: "";
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background-color: #e4e4e4;
    left: 1%;
    top: 48%;

}

.parent_dot2::before {
    content: "";
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background-color: #e4e4e4;
    left: 47%;
    top: 48%;

}

/* end */

.parent_outer_border1::before {
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    left: 1px;
    top: 127px;
    border: 1px solid #e4e4e4;
}

.parent_outer_border2::before {
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    left: 1px;
    top: 137px;
    border: 1px solid #e4e4e4;
}

/* dot and bordersection_left */


.personal_outer_border::before {
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    top: 135px;
    border: 1px solid #3b86f8;
    left: 100%;
}

.personal_dot1::before {
    content: "";
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background-color: #3b86f8;
    left: 54%;
    top: 48%;

}

.personal_dot2::before {
    content: "";
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background-color: #3b86f8;
    left: 101%;
    top: 48%;

}



.personal_outer_border_adative::before {
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    top: 169px;
    border: 1px solid blue;
    left: 100%;
}

/* end */









.pernal_learningimg_left img {
    margin-bottom: 9px;

    max-width: 140px;
}

.pernal_learningimg_left h3 {
    margin: 6px 0 7px 0;
    width: 43%;
    font-size: 28px;
    color: #357fef;
}

.pernal_learningimg_left p {
    width: 72%;
    font-size: 15px;

}




.comprhensive_content_right img {
    margin-bottom: 9px;

    max-width: 140px;
    text-align: end;
}

.comprhensive_content_right_heading {
    display: flex;
    justify-content: end;
}

.comprhensive_content_right h3 {

    margin: 6px 0 7px 0;
    width: 62%;
    font-size: 28px;
    color: #357fef;
    text-align: end;

}

.comprhensive_content_right p {
    width: 72%;
    font-size: 15px;
    text-align: end;

}


.comprhensive_content_right {
    display: flex;
    flex-direction: column;
    align-items: end;

}


@media (max-width: 575.98px) {

    .pernal_learningimg_left::before {
        display: none;
    }

    .comprhensive_content_right::before {
        display: none;
    }

    .personal_dot1::before {
        display: none;
    }

    .personal_dot2::before {
        display: none;
    }

    .personal_outer_border::before {
        display: none;
    }

    .personal_outer_border_adative::before {
        display: none;
    }

    .parent_outer_border1::before {
        display: none;
    }

    .parent_dot2::before {
        display: none;
    }

    .parent_dot::before {
        display: none;
    }

    .parent_outer_border::before {
        display: none;
    }

    .parent_outer_border2::before {
        display: none;
    }

    .pernal_learningimg_left {
        width: 100%;
        background: #fff;
        padding: 10px;
        border: 1px solid #eee;
        text-align: center !important;
    }

    .comprhensive_content_right {
        width: 100%;
        background: #fff;
        padding: 10px;
        border: 1px solid #eee;
        text-align: center !important;
    }

    .pernal_learningimg_left h3 {

        width: 99%;
        font-size: 17px;

    }

    .pernal_learningimg_left p {
        width: auto;
    }

    .comprhensive_content_right h3 {
        text-align: center;
        width: 171%;
        font-size: 17px;
    }

    .comprhensive_content_right_heading {
        justify-content: center;
    }

    .comprhensive_content_right {
        align-items: center;
    }

    .comprhensive_content_right p {
        width: 99%;
        font-size: 15px;
        text-align: center;
    }


    .parent_main_section {
        display: flex;
        justify-content: center;
        gap: 30px
    }


}