.mycards {
    height: 215px;
    /* width: 226px; */
    padding: 10px;
  }
  
  .myeffects {
    background-color: white;
    height: 33rem;
    padding: 17px;
    /* margin-top: 55px; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* justify-content: space-around; */
  }
  
  .row-2 {
    margin-top: 27px;
  }
  
  .allcourse {
    background-color: white;
    padding: 16px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 7px;
    height: 42rem;
  }
  
  
  .Enrollnow {
    background: #FF763F;
    color: #fff;
    font-size: 12px;
    text-decoration: none;
    text-transform: capitalize;
    width: 100%;
    padding: 15px 20px;
    text-align: center;
  }
  
  
  .courseimg_list {
    max-width: 270px;
   
    border-radius: 1rem;
  }
  
  .courseimg_list{
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  }
  
  .courseimg_list:hover {
    transform: scale(1.1);
   
  }
  
  .course-list-item{
  
    border-bottom: 1px solid #ddd;
    padding: 2rem;
  
  }
  
  
  .buttonlaunch{
    background: #264aca;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    text-transform: capitalize;
    width: 100%;
    padding: 10px 18px;
    text-align: center;
    border-radius: 25px;
  }
  
  
  
  .buttonlaunch:hover {
  
    background-color: #FF763F;
    color: #fff !important;
    
  }