.mycards {
  height: 215px;
  /* width: 226px; */
  padding: 10px;
}

.myeffects {
  background-color: white;
  height: 33rem;
  padding: 17px;
  /* margin-top: 55px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* justify-content: space-around; */
}

.row-2 {
  margin-top: 27px;
}

.allcourse {
  background-color: white;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 7px;
  height: 42rem;
}

.allcourse2 {
  background-color: white;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 7px;
  height: 45rem;

  margin-left: 19px;
  margin-right: 21px;
}

.prsazdczscfogress {
  display: flex;
  justify-content: end;
  margin-left: 22rem;

}


.studentpurched {
  display: flex;
  justify-content: space-between;
  /* background-color: #F2A0A0; */
}



.purchedcourse:hover {

  background-color: #264aca;
  color: #fff !important;

}