.aboutus_content {

    font: normal normal normal 41px/50px Sora;
    letter-spacing: 0px;
    color: #264ACA;
    padding-top: 45px;

}


.Content .content_description {
    top: -20px;
    left: 230px;
    position: relative;
    width: 1205px;
    height: 312px;

    /* UI Properties */
    text-align: center;
    font: normal normal normal 8px/36px Sora;
    letter-spacing: 0px;
    color: #6E6E6E;
    opacity: 1;

    z-index: -2;


}

.titleabout_text {
    font: normal normal normal 20px/30px Sora;
    width: 83%;
    padding-top: 45px;
}

.heading2.text-center h2 {
    position: relative;
    top: -80px;

    /* height: 121px; */
    /* UI Properties */
    text-align: center;
    font: normal normal normal 35px/45px Sora;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}



.aboutus_ourcourse {
    display: flex;
    align-items: center;
    justify-content: center;
}


.divid_coursefirst {
    top: 1557px;
    left: 960px;
    width: 589px;
    height: 365px;
    /* UI Properties */
    text-align: left;
    font: normal normal normal 20px/36px Sora;
    letter-spacing: 0px;
    color: #6E6E6E;
    opacity: 1;
}




.responsvive_text {
    display: none;
}








.image-aboutus {
    top: 1501px;
    left: 355px;
    width: 371px;
    height: 374px;
    /* UI Properties */
    /* background: transparent url('img/aboutus1.png') 0% 0% no-repeat padding-box; */
    opacity: 1;
}


.subtitle_aboutus {
    top: 1501px;
    left: 960px;
    /* width: 234px; */
    /* height: 36px; */
    /* UI Properties */
    /* text-align: center; */
    font: normal normal 600 29px/48px Sora;
    letter-spacing: 1.16px;
    color: #264ACA;
    opacity: 1;
    margin-bottom: 20px;
}

.about_resposiveready {
    display: none;
}

/* Media query for tablets */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .aboutus_content {
        font: normal normal normal 22px/32px Sora !important;
    }

    .aboutus_ourcourse .image-aboutus img {
        width: 80%;
        height: auto;

    }

    .divid_coursefirst {
        width: auto !important;
    }





    .newclass .row {
        display: flex;
        flex-wrap: wrap;
    }

    .newclass .col-lg-5 {
        width: 100%;
        margin-bottom: 20px;
        /* Adjust as needed */
    }

    .newclass .col-lg-2 {
        display: none;
        /* Hide the middle column */
    }

    .newclass .col-lg-5.new {
        width: 100%;
    }


}

/* Media query for larger screens */
@media only screen and (min-width: 1025px) {}



@media screen and (max-width: 520px) {

    .aboutus_content,
    .subtitle_aboutus {
        font: normal normal normal 22px/32px Sora !important;

    }

    .h2.banner-heading {
        font: normal normal normal 32px/48px Sora !important;
    }

    .divid_coursefirst {
        width: 100%;
    }

    .divid_coursefirst p {
        font-size: 16px;

    }

    .col-md-9.offset-md-2.secondrow {
        display: flex !important;
        justify-content: center !important;
    }

    .titleabout_text {
        font-size: 16px;
        padding-top: 30px;

        width: 100%
    }

    .aboutus_ourcourse .image-aboutus img {
        width: 48%;
        height: auto;

    }

    .aboutus_ourcourse .divid_coursefirst {
        font: normal normal normal 16px/28px Sora;

    }

    .divid_coursefirst {
        height: 300px;


    }

    .aboutus_ourcoursecol {
        display: flex;
        justify-content: center;
    }

    .aboutus_content,
    .subtitle_aboutus {
        font-size: 18px;

    }

    .aboutus_ourcourse2 .row {
        flex-direction: column !important;
    }

    .aboutus_ourcourse2 .image-aboutus img {
        width: 100%;
        height: auto;
        margin-top: 10px;

    }

    .image-aboutus {
        display: flex;
        justify-content: center;
        width: auto;
        height: auto;
    }



    .aboutus_ourcourse.newclass .row {
        flex-direction: column-reverse;

    }

    .aboutus_ourcourse.newclass .col-md-5,
    .aboutus_ourcourse.newclass .col-md-2,


    .aboutus_ourcourse.newclass .subtitle_aboutus,
    .aboutus_ourcourse.newclass .divid_coursefirst {
        margin: 0;

    }

    .aboutus_ourcourse.newclass .image-aboutus img {
        width: 48%;
        height: auto;
        margin-bottom: 10px;

    }

    .image-aboutus img {
        width: 48%;
        height: auto;
        margin-bottom: 10px;
    }

    .aboutus_ourcourse .image-aboutus.new {
        text-align: center;
    }

    .image-aboutus .new {
        width: 72%;
    }


    .aboutus_ourcourse.newclass .row.new-class {}

    .cta_bannerfooter {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
        flex-direction: column !important;
        gap: 2rem !important;
    }

    /* .cta_bannerfooter a {
                                    width: 50%;
                                } */

    .responsvive_text {
        position: absolute;
        top: 35%;

        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        text-align: center;
        display: block;
    }

    .about_text-white {
        color: white;
        font-size: 28px;
        margin-top: -80px;
    }

    .headingg .text-centerzzz {
        display: flex;
        justify-content: center;
    }

    .aboutus_content {
        text-align: center;
    }

    .about_sectionpargraph {

        text-align: center !important;
    }

    .subtitle_aboutus {
        padding-top: 2rem;
    }

    .about_resposiveready {
        display: block;
    }

}






@media (min-width: 563px) and (max-width: 768px) {

    .aboutus_content,
    .subtitle_aboutus {
        font: normal normal normal 21px/32px Sora !important;

    }

    .divid_coursefirst {
        width: 100%;
    }

    .divid_coursefirst p {
        font-size: 16px;

    }

    .col-md-9.offset-md-2.secondrow {
        display: flex !important;
        justify-content: center !important;
    }

    .titleabout_text {
        font-size: 16px;
        padding-top: 30px;

        width: auto !important;
    }

    .aboutus_ourcourse .image-aboutus img {
        width: 48%;
        height: auto;

    }

    .aboutus_ourcourse .divid_coursefirst {
        font: normal normal normal 16px/28px Sora;

    }

    .divid_coursefirst {
        height: 300px;


    }

    .aboutus_ourcoursecol {
        display: flex;
        justify-content: center;
    }

    .aboutus_content,
    .subtitle_aboutus {
        font-size: 18px;

    }



    .aboutus_ourcourse2 .image-aboutus img {
        width: 100%;
        height: auto;
        margin-top: 10px;

    }

    .image-aboutus {
        display: flex;
        justify-content: center;
        width: auto;
        height: auto;
    }

    .aboutus_ourcourse.newclass {
        display: none !important;
    }

    .consultancyresposive {
        display: block !important;
        padding-left: 23px !important;
    }



    .aboutus_ourcourse.newclass .col-md-5,
    .aboutus_ourcourse.newclass .col-md-2,


    .aboutus_ourcourse.newclass .subtitle_aboutus,
    .aboutus_ourcourse.newclass .divid_coursefirst {
        margin: 0;

    }

    .aboutus_ourcourse.newclass .image-aboutus img {
        width: 48%;
        height: auto;
        margin-bottom: 10px;

    }

    .image-aboutus img {
        width: 48%;
        height: auto;
        margin-bottom: 10px;
    }

    .aboutus_ourcourse .image-aboutus.new {
        text-align: center;
    }

    .image-aboutus .new {
        width: 72%;
    }

    .about_sectionpargraph {
        font-size: 18px !important;
    }

    .text-center {
        font-size: 20px !important;
    }

    .course_sectiontext h1 {
        width: 396px;

        font: normal normal normal 40px/54px Sora;

    }
}

/* @media screen and (max-width: 1200px) {
    .h2.banner-heading {
        font: normal normal normal 35px/49px Sora;
    }

    .aboutus_content,
    .subtitle_aboutus,
    .divid_coursefirst {
        font-size: 16px;
    }

    .titleabout_text {
        font-size: 14px;
    }

    .aboutus_ourcourse .image-aboutus img,
    .aboutus_ourcourse.newclass .image-aboutus img,
    .image-aboutus .new img {
        width: 100%;
        height: auto;
    }

    .col-md-5.new,
    .col-md-2,
    .divid_coursefirst,
    .subtitle_aboutus,
    .aboutus_ourcourse.newclass .divid_coursefirst,
    .aboutus_ourcourse.newclass .subtitle_aboutus {
        margin: 0;
    }

    .aboutus_ourcourse.newclass .row {
        flex-direction: column-reverse;
    }

    .image-aboutus .new {
        width: 100%;
    }
} */


.consultancyresposive {
    display: none;
}


@media (min-width: 769px) and (max-width: 820px) {

    .aboutus_content,
    .subtitle_aboutus {
        font: normal normal normal 21px/32px Sora !important;

    }

    .about_sectionpargraph {
        font-size: 18px !important;
    }

    .divid_coursefirst {
        width: 100%;
    }

    .divid_coursefirst p {
        font-size: 16px;

    }

    .col-md-9.offset-md-2.secondrow {
        display: flex !important;
        justify-content: center !important;
    }

    .titleabout_text {
        font-size: 16px;
        padding-top: 30px;

        width: auto !important;
    }

    .aboutus_ourcourse .image-aboutus img {
        width: 48%;
        height: auto;

    }

    .aboutus_ourcourse .divid_coursefirst {
        font: normal normal normal 16px/28px Sora;

    }

    .divid_coursefirst {
        height: 300px;


    }

    .aboutus_ourcoursecol {
        display: flex;
        justify-content: center;
    }

    .aboutus_content,
    .subtitle_aboutus {
        font-size: 18px;

    }



    .aboutus_ourcourse2 .image-aboutus img {
        width: 100%;
        height: auto;
        margin-top: 10px;

    }

    .image-aboutus {
        display: flex;
        justify-content: center;
        width: auto;
        height: auto;
    }

    .aboutus_ourcourse.newclass {
        display: none;
    }

    .consultancyresposive {
        display: block !important;
        padding-left: 23px !important;
    }



    .aboutus_ourcourse.newclass .col-md-5,
    .aboutus_ourcourse.newclass .col-md-2,


    .aboutus_ourcourse.newclass .subtitle_aboutus,
    .aboutus_ourcourse.newclass .divid_coursefirst {
        margin: 0;

    }

    .aboutus_ourcourse.newclass .image-aboutus img {
        width: 48%;
        height: auto;
        margin-bottom: 10px;

    }

    .image-aboutus img {
        width: 48%;
        height: auto;
        margin-bottom: 10px;
    }

    .aboutus_ourcourse .image-aboutus.new {
        text-align: center;
    }

    .image-aboutus .new {
        width: 72%;
    }

    .course_sectiontext h1 {
        width: 396px !important;

        font: normal normal normal 40px/54px Sora !important;

    }
}



@media (min-width: 821px) and (max-width:1024px) {

    .aboutus_content,
    .subtitle_aboutus {
        font: normal normal normal 21px/32px Sora !important;

    }

    .about_sectionpargraph {
        font-size: 18px !important;
    }

    .divid_coursefirst {
        width: 100%;
    }

    .divid_coursefirst p {
        font-size: 16px;

    }

    .col-md-9.offset-md-2.secondrow {
        display: flex !important;
        justify-content: center !important;
    }

    .titleabout_text {
        font-size: 16px;
        padding-top: 30px;

        width: auto !important;
    }

    .aboutus_ourcourse .image-aboutus img {
        width: 48%;
        height: auto;

    }

    .aboutus_ourcourse .divid_coursefirst {
        font: normal normal normal 16px/28px Sora;

    }

    .divid_coursefirst {
        height: 300px;


    }

    .aboutus_ourcoursecol {
        display: flex;
        justify-content: center;
    }

    .aboutus_content,
    .subtitle_aboutus {
        font-size: 18px;

    }



    .aboutus_ourcourse2 .image-aboutus img {
        width: 100%;
        height: auto;
        margin-top: 10px;

    }

    .image-aboutus {
        display: flex;
        justify-content: center;
        width: auto;
        height: auto;
    }

    .aboutus_ourcourse.newclass {
        display: none;
    }

    .consultancyresposive {
        display: block !important;
        padding-left: 23px !important;
    }



    .aboutus_ourcourse.newclass .col-md-5,
    .aboutus_ourcourse.newclass .col-md-2,


    .aboutus_ourcourse.newclass .subtitle_aboutus,
    .aboutus_ourcourse.newclass .divid_coursefirst {
        margin: 0;

    }

    .aboutus_ourcourse.newclass .image-aboutus img {
        width: 48%;
        height: auto;
        margin-bottom: 10px;

    }

    .image-aboutus img {
        width: 48%;
        height: auto;
        margin-bottom: 10px;
    }

    .aboutus_ourcourse .image-aboutus.new {
        text-align: center;
    }

    .image-aboutus .new {
        width: 72%;
    }

    .course_sectiontext h1 {
        width: 396px !important;

        font: normal normal normal 40px/54px Sora !important;

    }

    h2.banner-heading {
        font: normal normal normal 33px/60px Sora !important
    }
}