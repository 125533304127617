.Careers-section span {
    top: 734px;
    left: 668px;
    width: 584px;
    height: 183px;
    /* UI Properties */
    text-align: center;
    font: normal normal normal 48px/61px Sora;
    letter-spacing: 0px;
    color: #264ACA;
    opacity: 1;
}

.careers-circleorange {
    font-size: 13px !important;
}



.Careers-section {
    justify-content: center;
    text-align: center;
    display: flex;
}

.Careers-dis {
    justify-content: center;
    text-align: center;
    display: flex;
}

.Careers-dis p {
    top: 897px;
    left: 447px;
    width: 1026px;
    height: 260px;
    /* UI Properties */
    text-align: center;
    font: normal normal normal 24px/36px Sora;
    letter-spacing: 0px;
    color: #6E6E6E;
    opacity: 1;
}

.career-digit {
    display: flex;
    justify-content: center;
    text-align: center;
}


/* //smal class */

.careers-circle {
    opacity: 0.8;
    font-size: 16px;

}

.careers-circle.active-circle {
    opacity: 1;
    font-size: 20px;

}

.careers-circlewhitesmall {
    width: 100px;
    height: 100px;
    background-color: #F7F7F7;
    /* Change the color as needed */
    border-radius: 50%;
    transition: transform .2s;
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    padding: 20px;

}

/* 
.careers-circlewhitesmall:hover {
    transform: scale(1.3);
} */

.element-with-smooth-scroll {
    scroll-behavior: smooth;
}

.careers-circleorangesmall {
    width: 90px;
    height: 90px;
    margin: 2rem;
    background-color: #FF763F;
    /* Change the color as needed */
    border-radius: 50%;
    transition: transform .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px;
}

.careers-circleorangesmall:hover {
    transform: scale(1.3);
}


.careers-circleorangemedium {
    width: 110px;
    height: 110px;
    margin: 2rem;
    background-color: #FF763F;
    /* Change the color as needed */
    border-radius: 50%;
    transition: transform .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px;
}

.careers-circleorangemedium:hover {
    transform: scale(1.3);
}

.careers-circle.circle-xs {
    width: 85px;
    height: 85px;
    font-size: 11px;
}

.careers-circle.circle-sm {
    width: 110px;
    height: 110px;
    font-size: 11px;
}

.careers-circle.circle-lg {
    width: 135px;
    height: 135px;
    font-size: 13px;
}

.careers-circle.circle-md {
    width: 165px;
    height: 165px;
    font-size: 13px;
}

.careers-circlegreysmall {
    width: 110px;
    height: 110px;
    background-color: #6E6E6E;
    /* Change the color as needed */
    border-radius: 50%;
    transition: transform .2s;
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px;
    font-size: 13px;


}

.careers-circlegreysmall:hover {
    transform: scale(1.3);
}


.careers-circlebluesmall {
    width: 130px;
    height: 130px;
    background-color: #264ACA;
    /* Change the color as needed */
    border-radius: 50%;
    transition: transform .2s;
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px;
    opacity: 0.7;
    font-size: 13px;
}

.careers-circlebluesmall:hover {
    transform: scale(1.3);
}

.careers-circleblueverysmall {
    width: 90px;
    height: 90px;
    background-color: #264ACA;
    /* Change the color as needed */
    border-radius: 50%;
    transition: transform .2s;
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px;


}

.careers-circleblueverysmall:hover {
    transform: scale(1.3);
}





















.careers-circleorange {
    width: 130px;
    height: 130px;
    margin: 2rem;
    background-color: #FF763F;
    /* Change the color as needed */
    border-radius: 50%;
    transition: transform .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px;
}

.careers-circleorange:hover {
    transform: scale(1.3);
}

.careers-circlegrey {
    width: 130px;
    height: 130px;
    background-color: #6E6E6E;
    /* Change the color as needed */
    border-radius: 50%;
    transition: transform .2s;
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px;


}

.careers-circlegrey:hover {
    transform: scale(1.3);
}

.careers-circlewhite {
    width: 130px;
    height: 130px;
    background-color: #F7F7F7;
    /* Change the color as needed */
    border-radius: 50%;
    transition: transform .2s;
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    padding: 20px;

}

.careers-circlewhite:hover {
    transform: scale(1.3);
}


.careers-circleblue {
    width: 220px;
    height: 220px;
    background-color: #264ACA;
    /* Change the color as needed */
    border-radius: 50%;
    transition: transform .2s;
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px;


}


.careers-circlebluelarge {
    width: 170px;
    height: 170px;
    background-color: #264ACA;
    /* Change the color as needed */
    border-radius: 50%;
    transition: transform .2s;
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px;
}

.white-text {
    color: white;
    /* Set the color as needed */
}

.careers-circlebluelarge:hover {
    transform: scale(1.3);
}

.careers-circleblue:hover {
    transform: scale(1.3);
}


.Dynamic-Content {

    height: 58px;


    font: normal normal normal 46px/58px Sora;
    letter-spacing: 0px;
    color: #264ACA;
    opacity: 1;
}

.contentCareers-section2 {
    height: 58px;


    font: normal normal normal 46px/58px Sora;
    letter-spacing: 0px;
    color: black;
    opacity: 1;

}

.security-careerspath {
    height: 58px;


    font: normal normal normal 35px/58px Sora;
    letter-spacing: 0px;
    color: black;
    opacity: 1;
}

.blackcircle {
    width: 15px;
    height: 15px;
    background-color: black;
    /* Change the color as needed */
    border-radius: 50%;
    transition: transform .2s;

}


.active-circle {
    width: 200px;
    /* Adjust the width and height as needed */
    height: 200px;
    /* Add any other styles for the enlarged state */
    position: relative;
}

.active-circle::after {
    /* content: ''; */
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-width: 15px;
    /* Adjust the size of the arrow */
    border-style: solid;
    border-color: white transparent transparent transparent;
    /* Down arrow */
}




.career-pargraph {

    width: 1432px;
    height: 610px;
    /* UI Properties */
    text-align: left;
    font: normal normal normal 17px/36px Sora;
    letter-spacing: 0px;
    color: #6E6E6E;
    opacity: 1;
    padding-bottom: 20px;
}

.digit p {

    width: 532px;
    height: 57px;
    text-align: center;
    letter-spacing: 0px;
    color: #264ACA;
    text-transform: uppercase;
    opacity: 1;
    font-size: 35px;
}

.career-digit {


    height: 44px;
    text-align: left;
    font: normal normal normal 18px/22px Sora;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}

.careersul {



    font: normal normal normal 24px/30px Sora;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    padding: 0px;

}


.careersli {

    display: inline-block;
    border-bottom: 1px solid #FF763F;
    opacity: 1;
    padding: 10px;
    font: normal normal normal 18px/30px Sora;
    width: 74%;


}


.careers {
    display: inline-block;

    opacity: 1;
    padding: 10px;
    font: normal normal normal 18px/30px Sora;
}

.career-title {
    font: normal normal 600 20px/27px Sora;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
}


#kt_app_body {
    overflow-x: hidden !important;
}

/* .app-default {
    background: white;
} */
.careersul {



    font: normal normal normal 24px/30px Sora;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    padding: 0px;

}

.dynamictitle-careers {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 20px;
}







.subtitle {
    font-size: 17px;
}





.careerbotombanner {
    font-size: 30px;
    color: #F7F7F7;
}


/* styles.css or your global stylesheet */
.careers-tab .nav-link {
    background: none;
    width: calc(100% / var(--tab-count));
    /* Using CSS variables for dynamic width */
    font-size: 18px;
    font-weight: 600;
}

/* Add any additional styles or media queries here */
.careers_pagecoutry {
    display: flex;
}

.form-select.country_select {
    width: 350px;
    borderRadius: 40px;
}

.Responsive_careers {
    display: none;
}

.careerbottom_backgound {
    width: 100%;
    height: auto;
}

.careerbotombanner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 48px;
}


/* .circleoverflow {
    width: 100vw;
    overflow-x: auto;
} */

.swipe_careers {
    display: none !important;
}


@media screen and (max-width: 520px) {
    .nav-link {
        width: 27.3333%;
        font-size: 13px;
        font-weight: 600;
        background: none;
    }

    .circleoverflow {
        padding-left: 100px !important;
    }

    .career-pargraph {
        width: 100%;
        font: normal normal normal 14px/28px Sora;
    }

    .careers_pagecoutry {
        display: flex;
        flex-direction: column;
    }

    .TypicalSalary {
        flex-direction: column;
    }

    .text-start {
        text-align: center !important;
        font-size: 17px !important;
    }

    .form-select.country_select {
        width: 270px;
        border-radius: 40px;
    }

    .career-digit {
        font: 10px / 15px Sora !important;
        color: rgb(112, 112, 112);
        width: 100% !important;
        margin-left: 8px;
    }

    .recomanded_careers {
        display: none;
    }

    .Responsive_careers {
        display: block;
    }

    .careerbottom_backgound {
        height: 500px;
        padding-top: 8rem !important;
    }

    .careerbotombanner {
        text-align: center;
        color: white;
        font-size: 30px;
        width: 356px;
    }

    .Careers-section span {

        width: 336px;
        height: 79px;
        font: normal normal normal 26px/29px Sora;
    }

    .Careers-dis p {
        width: 369px;
        font: normal normal normal 16px/28px Sora;

    }

    .text-center {
        font-size: 16px !important;
    }

    .careers-tab .nav-tabs .nav-link.active {
        border-bottom: 3px solid #FF763F !important;
        color: black;
        font-size: 12px !important;
    }

    .careers-tab .nav-tabs .nav-link {
        font-size: 12px;
    }

    .career-title {
        font: normal normal 600 18px/27px Sora !important;

    }

    .careersli {


        font: normal normal normal 16px/22px Sora;



    }

    .swipe_careers span {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .subtitle {
        font-size: 12px;
    }

    .Recommended {
        text-align: center;
        font-size: 26px !important;
    }



    .d-flex {
        display: flex;
        /* justify-content: center; */
    }

    .digit_pargarph {
        font-size: 16px !important;

    }

    .digit p {
        width: auto !important;
    }


    .hrlincareer {
        width: fit-content !important;
    }

    .swipe_careers {
        display: block !important;
    }


    .careers-circle.circle-lg {
        width: 99px !important;
        height: 100px !important;
        font-size: 10px !important;
    }

    .careers-circle.circle-md {
        width: 130px !important;
        height: 124px !important;
        font-size: 10px !important;
    }

    .careers-circle.circle-sm {
        width: 113px !important;
        height: 110px !important;
        font-size: 10px !important;
    }

    .careers-circlebluesmall {
        width: 125px !important;
        height: 121px !important;
        font-size: 10px !important;
    }

    .careers-circleorange {
        width: 104px !important;
        height: 100px !important;
        font-size: 10px !important;
    }

    .blackcircle {
        width: 6px;
        height: 6px;

    }

    .career_pathline {
        height: 200px !important;
    }

    .blackcircle .blackcircle2 .subtitle {
        margin-top: -50px !important;
    }

    .career-path {
        margin-top: 2rem !important;
    }

    .recomanded_course {
        font-size: 14px !important;
    }
}




@media (min-width: 563px) and (max-width: 768px) {

    /* .circleoverflow {
        width: 100vw !important;
        overflow-x: auto;
    } */
    .recomanded_course {
        font-size: 14px !important;
    }

    .swipe_careers span {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .container {
        width: auto !important;
        margin-left: 0px !important;
    }

    .career-pargraph {
        width: auto !important;
        font: normal normal normal 16px/33px Sora !important;
    }

    .careers_pagecoutry {
        display: flow-root;
    }

    .Careers-section span {
        font: normal normal normal 36px/41px Sora !important;
    }

    .Careers-dis p {
        width: auto !important;
        font: normal normal normal 16px/31px Sora !important;
    }

    .careerbotombanner {
        font-size: 32px !important;
    }

    .careerbottom_backgound {
        height: 351px !important;
    }

    .career-digit {
        font: 12px / 22px Sora !important;
        margin-left: 8px;
    }

    .text-center .pb-10 {
        font-size: 20px !important;
    }

    .Recommended .text-center .pb-10 {
        font-size: 25px !important;
    }

    .careers-tab .nav-link {

        font-size: 16px !important;

    }

    .career-title {
        font: normal normal 600 16px/27px Sora !important;

    }

    .Recommended.text-center .pb-10 {
        font-size: 30px !important;
    }

    .text-center.pb-10 {
        font-size: 30px !important;
    }

    .text-center {
        font-size: 20px !important;
    }

    .digit_pargarph {
        font-size: 16px !important;

    }

    .digit p {
        width: auto !important;
    }

    .hrlincareer {
        width: fit-content !important;
    }

    .swipe_careers {
        display: block !important;
    }

    .circleoverflow {
        padding-left: 100px !important;
    }
}


@media (min-width: 769px) and (max-width: 820px) {

    /* .circleoverflow {
        width: 100%;
        overflow-x: auto;
    } */
    .recomanded_course {
        font-size: 14px !important;
    }

    .container {
        width: auto !important;
        margin-left: 0px !important;
    }

    .career-pargraph {
        width: auto !important;
        font: normal normal normal 16px/33px Sora !important;
    }

    .careers_pagecoutry {
        display: flow-root;
    }

    .Careers-section span {
        font: normal normal normal 36px/41px Sora !important;
    }

    .Careers-dis p {
        width: auto !important;
        font: normal normal normal 16px/31px Sora !important;
    }

    .careerbotombanner {
        font-size: 32px !important;
    }

    .careerbottom_backgound {
        height: 351px !important;
    }

    .career-digit {
        font: 12px / 22px Sora !important;
        margin-left: 8px;
    }

    .text-center .pb-10 {
        font-size: 20px !important;
    }

    .Recommended .text-center .pb-10 {
        font-size: 25px !important;
    }

    .careers-tab .nav-link {

        font-size: 16px !important;

    }

    .career-title {
        font: normal normal 600 16px/27px Sora !important;

    }

    .Recommended.text-center .pb-10 {
        font-size: 30px !important;
    }

    .text-center.pb-10 {
        font-size: 30px !important;
    }

    .text-center {
        font-size: 20px !important;
    }

    .footer_res {
        padding-top: 136px !important;
    }

    .circleoverflow {
        padding-left: 100px !important;
    }
}


@media (min-width: 821px) and (max-width:1024px) {

    /* .circleoverflow {
        width: 100%;
        overflow-x: auto;
    } */
    .recomanded_course {
        font-size: 14px !important;
    }

    .container {
        width: auto !important;
        margin-left: 0px !important;
    }

    .career-pargraph {
        width: auto !important;
        font: normal normal normal 16px/33px Sora !important;
    }

    .careers_pagecoutry {
        display: flow-root;
    }

    .Careers-section span {
        font: normal normal normal 36px/41px Sora !important;
    }

    .Careers-dis p {
        width: auto !important;
        font: normal normal normal 16px/31px Sora !important;
    }

    .careerbotombanner {
        font-size: 32px !important;
    }

    .careerbottom_backgound {
        height: 351px !important;
    }

    .career-digit {
        font: 12px / 22px Sora !important;
    }

    .text-center .pb-10 {
        font-size: 20px !important;
    }

    .Recommended .text-center .pb-10 {
        font-size: 25px !important;
    }

    .careers-tab .nav-link {

        font-size: 16px !important;

    }

    .career-title {
        font: normal normal 600 16px/27px Sora !important;

    }

    .Recommended.text-center .pb-10 {
        font-size: 30px !important;
    }

    .text-center.pb-10 {
        font-size: 30px !important;
    }

    .text-center {
        font-size: 20px !important;
    }

    /* .footer_res {
        padding-top: 136px !important;
    } */
}