.rmdp-full-year {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.ant-modal-content {
  width: fit-content;
}

.rmdp-range:has(.reserved) {
  background-color: #1e7109;
  pointer-events: none;
}

/* .rmdp-range:has(.in-service) {
  background-color: #46a5db;
} */

.reserved {
  background-color: #3f826d;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
}

.rmdp-range:has(.reserved) {
  background-color: #3f826d;
  pointer-events: none;
}

div.reserved {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

div.in-service {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.un-availble {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.un-availble p {
  margin: 0;
}

.custom-calendar .disabled {
  color: #b3bcc7;
  pointer-events: none;
}

div.in-service {
  background-color: #8fb4f9;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.rmdp-day.rmdp-deactive,
.rmdp-day.rmdp-disabled {
  color: #b3bcc7;
}


:where(.css-dev-only-do-not-override-yp8pcc).ant-modal .ant-modal-content {

  width: 82rem;
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 20px 24px;
  left: -169px;
}


:where(.css-dev-only-do-not-override-1vr7spz).ant-modal-root .ant-modal-mask {
  position: fixed;
  inset: 0;
  z-index: 1000;
  height: 100%;
  background-color: none !important;
  pointer-events: none;

}


:where(.css-dev-only-do-not-override-1vr7spz).ant-modal-root .ant-modal-mask {
  position: fixed;
  inset: 0;
  z-index: 1000;
  height: 100%;
  background-color: transparent !important;
  /* Set background color to transparent */
  pointer-events: none;
}







@media screen and (max-width: 520px) {
  .rmdp-day-picker .rmdp-full-year {
    display: contents !important;
  }

  .calender_header {
    display: inline !important;
  }
}