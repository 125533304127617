.copyright_degine {
    display: flex;
}








@media screen and (max-width: 520px) {
    .footerstart {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .footertitle {
        display: flex;
        justify-content: center;
    }

    .footer .brand {
        max-width: 365px !important;
    }

    .footer ul {
        margin-bottom: 0px !important;

    }

    .footer ul li {
        padding-bottom: 2rem !important;
    }

    .footer_ul {
        padding-top: 5rem !important;
    }

    .socai-icons li a {
        width: 49px !important;
    }

    .copyright_degine {
        flex-direction: column !important;
        padding-top: 2rem;
    }

    .copyright_degineterm {
        padding-top: 2rem;
    }

    .footer ul li a {
        font-size: 18px !important;
    }

    .legal {
        color: white;
        flex-direction: column;
        display: flex;
        text-align: center;

    }

    .brand img {
        width: 74%;

    }

    .footer .email {
        font-size: 18px !important;
    }

}


@media (min-width: 521px) and (max-width: 768px) {

    .footer_ul {
        font-size: 17px !important;
    }

    .footer_ul1 {
        font-size: 17px !important;




    }

    .email {
        font-size: 13px !important;
    }

    .socai-icons {
        margin-left: 6rem !important;
    }

}


@media screen and (max-width: 1200px) {
.footerstart {
    display: flex;
    justify-content: space-evenly;
}

}


@media screen and (max-width: 1200px) {
.footer_content {
 font-size: 20px;
 
}
.footer_content_email {
    display: contents;
 font-size: 20px;
}
}