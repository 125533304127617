.dimension {
    width: 500px;
    height: 300px;
}



/*section tabs*/
.preview_sadow .nav-tabs {
    border: none;
}

.preview_sadow .nav-tabs .nav-link {
    padding: 0;
    font-weight: 500;
    font-size: 20px;
    color: #000;

    margin: 0 10px;
}

.preview_sadow .nav-tabs .nav-link.active {
    border: none;

    color: white;
    background: rgb(2, 31, 89);
    padding: 15px;
    border-radius: 1px;

}

.preview_sadow .nav-tabs {}



.preview_sadow .nav-tabs .nav-link {
    background: #F2F2F2;
    padding: 15px;
    border: none;
    border-radius: 1px;
}



































.nav-itemm {
    width: 546px;
}

.nav-tabs.nav-link {

    color: rgba(0, 0, 0, 0.75);
    background: #FF763F;





}

.nav.nav-tabs {
    display: flex;
    text-align: center;
    /* justify-content: center; */
}

.btnfollow {
    border-radius: 24px;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    background: #1b2137;
    color: white;
}

.accordion-header button {

    color: #3a3a3a;
}


.accordion {

    border-radius: 8px;
    overflow: hidden;
    /* -webkit-box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.08), 1px 0px 1px rgba(0, 0, 0, 0.08), -1px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08); */
}


/* .video_learning{
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-surface-border);
    background-color: var(--color-surface);
} */
.accordion-item {
    border-bottom: 1px solid rgba(255, 255, 255, .35);
    background-color: transparent;
    color: #1b2137;
}



.accordion-button::after {
    color: white;
}


.jobTile {
    padding: 7px;
    background: rgb(2, 31, 89);
    /* width: 380px; */

}


.jobtext-color {
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}