.rmdp-header .rmdp-month-name {
  font-size: 21px;
}

select#EducationLevel {
  width: 51rem;
  /* margin-top: 12px; */
  height: 43px;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
}

.custom-datepicker {
  border-radius: 5px;
  width: 321px;
  padding: 5px;
  font-size: 16px;

  border: 1px solid grey;
}

.rmdp-full-year {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.rmdp-shadow.custom-calendar.rmdp-wrapper.rmdp-shadow.custom-calendar {
  z-index: 0 !important;
}

/* 
.rmdp-day-picker {

  padding-left: 76px;
  padding-right: 76px;
} */

.custom-calendar .rdrCalendarWrapper {
  display: flex;
  flex-wrap: wrap;
  width: "120%";
}

.rmdp-day-picker .rmdp-full-year {
  display: grid;
  color: black;
  font-size: 50px;
  gap: 34px;
  /* padding-left: 76px;
  padding-right: 76px; */
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.custom-calendar .rdrMonth {
  width: 25%;
}

.rmdp-shadow {
  box-shadow: unset;
  width: 100%;
}

.rmdp-month-name {

  font-weight: bold;
}

.rmdp-header {
  font-size: 14px;
  margin-top: 5px;
  padding: 9px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.rmdp-header-values {
  font-size: 40px;
  margin-bottom: 20px;
  margin: 0px;

  text-align: center;
  justify-content: center;
  align-items: center;
}

.calander-buttons {
  display: flex;
  gap: 20px;
  margin-top: 18px;
  margin-bottom: 2rem;
  position: relative;
}

.month-day-time {
  display: flex;
  margin-top: 12px;
  gap: 80px;
}

.separator.separator-dashed {
  border-bottom-style: dashed;
  border-bottom-color: var(--bs-border-dashed-color);
  width: 72rem;
  padding-top: 10px;

}

/* .select-time {
    margin-left: 10px;
  } */

.available {
  font-size: 15px;
  font-weight: 600;
}

.form-styele {
  margin-top: 10px;

}

.innline {
  margin-top: 10px;
}

.rmdp-range:has(.reserved) {
  background-color: green;
  pointer-events: none;
}


.reserved {
  background-color: green;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
}

.calendar-row {
  column-gap: 20px;
}

.rmdp-wrapper {

  border-radius: 5px;
  direction: ltr;
  text-align: center;
}

.in-service {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.un-availble {
  display: flex;
  align-items: center;
}

.un-availble p {
  margin: 0;
}

.rmdp-calendar {
  width: 100%;
}

.rmdp-top-class .rmdp-border-bottom {
  display: flex;
  width: 80rem;
}

.rmdp-shadow {
  box-shadow: 0 0 5px #8798ad;

}

.rmdp-day span,
.rmdp-week-day {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1px;
  align-items: center;
}


.calander-buttons .button {
  border-radius: 5px !important;
}

.rmdp-week {
  display: flex;
  justify-content: center;
}

















.circle {
  width: 10px;
  height: 10px;

  display: inline-block;
  margin-right: 10px;
}

.green {
  background-color: green;
}

.blue {
  background-color: #0074d9;
}

.grey {
  background-color: grey;
}

.booked {
  color: green;

}

.unbooked {
  color: #0074d9;
}

.greyyy {
  color: grey;
}




.loreamul {
  list-style-type: circle;
  padding-left: 0;
}












.rmdp-header-values span {
  padding: unset !important;
}