.fa {
    margin-right: 5px;
}

.rating .fa {
    font-size: 22px;
}

.rating-num {
    margin-top: 0px;
    font-size: 60px;
}

.progress {
    margin-bottom: 5px;
}

.progress-bar {
    text-align: left;
}

.rating-desc .col-md-3 {
    padding-right: 0px;
}

.sr-only {
    margin-left: 5px;
    overflow: visible;
    clip: auto;
}

.progress-bar {
background-color: #c37d16;
}