.nav-link {

  color: #333;
  text-decoration: none;
}

.navbar-nav .nav-link.active {
  color: white;
  /* border: */
  border-bottom: 4px solid #FF763F;
}


.active-link {

  font-weight: bold;
  color: blue;

}



.responsive_banner {
  display: none;
}

.bannerresonsv {
  display: none;
}

.responsvive_aboutus {
  display: none;
}

.navbar.fixed-color {
  background-color: #0a1851; 
  transition: background-color 0.3s;
}



@media screen and (max-width: 520px) {


  .responsive_banner {
    display: block;
  }

  .homeBanner {
    display: none;
  }

  .bannerresonsv {
    display: block;
  }

  .consult {
    display: none;
  }

  .have_goal {
    text-align: center;
  }

  .hero-video {
    position: relative;

    height: 418px;
    /* Set your desired fixed height */
    overflow: hidden;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .responsvive_aboutus {
    display: block;
  }

  .about_page {
    display: none;
  }

  #left-tabs-example-tab-bookedInstructor {
    width: auto !important;
  }

  .nav-pills .nav-link.active {
    width: auto !important;
  }

  .selectcourse_voucher {
    width: auto !important;
  }

  .slect_course_lablel {
    font-size: 14px !important;
    width: 100% !important;
  }

  .student_vochers {
    width: auto !important;
  }

  .w-full.sm\:w-20rem.p-multiselect.p-component.p-inputwrapper.p-multiselect-chip.p-inputwrapper-filled {
    width: 268px !important;
  }
}




@media (min-width: 563px) and (max-width: 768px) {

  .courses-tab .nav-tabs .nav-link {
    margin: 0px !important;
    font-size: 13px !important;
  }

  .have_goal_sapn {
    font-size: 40px !important;
    top: 10rem !important
  }

  .col-md-5 {
    width: auto !important;
  }

  .startnow {
    width: 100% !important;
  }

  .app-default {
    /* width: fit-content !important; */
  }

  .home-paragarph {
    width: auto !important;
  }

  h2.banner-heading {
    font: normal normal normal 30px/47px Sora !important;
  }



  .homeBanner .hero-video {


    height: 600px !important;


  }

  .have_goal {
    margin-top: -12rem !important;

  }

  .consult .hero-video {


    height: 600px !important;


  }

  .focusbusiness {
    font-size: 20px !important;
  }

  .protecting {
    font-size: 32px !important;
  }

  .drawer-overlay {
    background-color: rgb(255, 255, 255) !important;
    box-shadow: rgba(22, 22, 26, 0.18) 2px 6px 8px 0px !important;
  }

  .testimonial-main {
    height: 544px !important;
  }

}

@media (min-width: 769px) and (max-width: 820px) {
  .courses-tab .nav-tabs .nav-link {
    margin: 0px !important;
    font-size: 13px !important;
  }

  .have_goal_sapn {
    font-size: 40px !important;
    top: 10rem !important
  }

  .col-md-5 {
    width: auto !important;
  }

  .startnow {
    width: 100% !important;
  }

  /* .app-default {
                  width: fit-content !important;
                } */

  .home-paragarph {
    width: auto !important;
  }

  h2.banner-heading {
    font: normal normal normal 30px/47px Sora !important;
  }



  .homeBanner .hero-video {


    height: 600px !important;


  }

  .have_goal {
    margin-top: -12rem !important;

  }

  .consult .hero-video {


    height: 600px !important;


  }

  .focusbusiness {
    font-size: 20px !important;
  }

  .protecting {
    font-size: 32px !important;
  }

  .drawer-overlay {
    background-color: rgb(255, 255, 255) !important;
    box-shadow: rgba(22, 22, 26, 0.18) 2px 6px 8px 0px !important;
  }

  .testimonial-main {
    height: 544px !important;
  }

}

@media (min-width: 821px) and (max-width: 1024px) {
  .button {
    padding: 10px 21px !important;
  }


  .courses-tab .nav-tabs .nav-link {
    margin: 0px !important;
    font-size: 17px !important;
  }

  .have_goal_sapn {
    font-size: 40px !important;
    top: 15rem !important
  }

  .col-md-5 {
    width: auto !important;
  }

  .startnow {
    width: 100% !important;
  }

  /* .app-default {
                    width: fit-content !important;
                  } */

  .home-paragarph {
    width: auto !important;
  }



  h2.banner-heading {
    font: normal normal normal 30px/47px Sora !important;
  }



  .homeBanner .hero-video {


    height: 600px !important;


  }

  .have_goal {
    margin-top: -12rem !important;

  }

  .consult .hero-video {


    height: 600px !important;


  }

  .focusbusiness {
    font-size: 20px !important;
  }

  .protecting {
    font-size: 32px !important;
  }

  .drawer-overlay {
    background-color: rgb(255, 255, 255) !important;
    box-shadow: rgba(22, 22, 26, 0.18) 2px 6px 8px 0px !important;
  }

  .testimonial-main {
    height: 544px !important;
  }

}
