.dimension {
    width: 500px;
    height: 300px;
}

.nav-tabs .nav-link.active {


    border-bottom: 7px solid #FF763F;
    color: black;

}



.nav-tabs.nav-link {

    color: rgba(0, 0, 0, 0.75);





}

.nav.nav-tabs {
    display: flex;
    text-align: center;
    /* justify-content: center; */
}

.btnfollow {
    border-radius: 24px;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    background: #1b2137;
    color: white;
}

.accordion-header button {

    color: #3a3a3a;
}


.accordion {

    border-radius: 8px;
    overflow: hidden;
    /* -webkit-box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.08), 1px 0px 1px rgba(0, 0, 0, 0.08), -1px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08); */
}


/* .video_learning{
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-surface-border);
    background-color: var(--color-surface);
} */
.accordion-item {
    border-bottom: 1px solid rgba(255, 255, 255, .35);
    background-color: transparent;
    color: #1b2137;
}



.accordion-button::after {
    color: white;
}



.video_learning .nav-tabs .nav-link {
    color: #333;
}


/* background-color: #d1d7dc; */
.highlighted-lesson {
    background-color: #d1d7dc;
}